﻿/// <reference path="../main">

%flex-mobile-form-item {
    @extend %flex-fiftyfifty;
    align-items: center;

    @include responsive-context($gutter-map) {
        & > * {
            &:first-child {
                width: calc(35% - #{$responsive-variable / 2});
            }

            &:last-child {
                flex: 1 1 auto;
            }
        }
    }

    @include media-min(medium-small) {
        display: block;

        & > * {
            width: 100%;

            &:first-child {
                width: auto;
            }
        }
    }

    @include media(x-small) {
        display: block;

        & > *:nth-child(n) {
            width: 100%;
            margin-right: 0;
            text-align: left;
        }
    }

    & > * + *:nth-child(n) {
        @extend %small-top-margin;
    }

    @include media(medium-small) {
        [class*="btn"] {
            flex: 0 0 auto;
            width: auto;
        }
    }

    @include media-range(x-small, medium-small) {
        & > *:first-child {
            text-align: right;
        }

        &:last-child {
            margin-bottom: 0;
        }

        & > * + *:nth-child(n) {
            margin-top: 0;
        }
    }
}
