﻿/// <reference path="../../main">

[data-watermark] {
    position: relative;
    overflow: hidden;

    &::before {
        @extend %watermark;
        content: attr(data-watermark);
        position: absolute;
        left: 30%;
        top: $gap-lg;
        transform: translateX(-40%);
        pointer-events: none;
        // arbitrary width to make a reasonable line break
        width: $medium-media-query-size;

        @include media(small) {
            width: $medium-media-query-size * 0.75;
        }
    }

    & ~ *,
    & > * {
        position: relative;
    }
}

.watermark-align {
    &-right {
        &::before {
            right: 0;
            white-space: normal;
            text-align: right;
            transform: translateX(10%);

            @include responsive-context($vertical-margin-map) {
                top: $responsive-variable + $gap;
            }
        }
    }
}

// theming
@each $color, $value in $colors {
    .theme-#{$color}[data-watermark],
    .theme-#{$color} [data-watermark] {
        &::before {
            color: map-get($value, 'watermark');
        }
    }
}