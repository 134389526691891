﻿/// <reference path="../../main">

input[type="checkbox"] {
    & + label,
    & + input[type="hidden"] + label {
        &::after {
            border: 2px solid $_radiocheck-foreground;
            border-width: 0 0 3px 3px;
            transform: rotate(-53deg) skew(-18deg) scale(0.9);
            display: block;
            top: 0.15em;
            left: 0.08em;
        }

        &::before {
            border-radius: 0;
        }
    }

    &:hover,
    &:checked {
        & + label,
        & + input[type="hidden"] + label {
            &::after {
                border-color: $_radiocheck-accent;
            }
        }
    }
}

// SIZING
input[type="checkbox"] {
    & + label,
    & + input[type="hidden"] + label {
        &::after {
            width: $_radiocheck-size / 1.125;
            height: $_radiocheck-size / 2;
        }
    }
}