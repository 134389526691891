﻿/// <reference path="../../main">

.x-btn {
    @extend %x-btn;
}

.loading-btn {
    &::after {
        color: $gray-med;
        content: '';
        display: block;
        font-size: 0.25rem;
        margin: 0 auto;
        width: 1em;
        height: 1em;
        border-radius: 50%;
        position: relative;
        animation: spinner 1.3s infinite linear;
        transform: translate3d(-100%, -50%, 0);
        position: absolute;
        top: 50%;
        right: $_forms-input-padding-h;
    }

    &::before {
        content: none;
    }
}

.btn-search,
.btn-search-xl {
    @include button-helper();

    &::before {
        content: none;
    }

    &::after {
        @include ico-search($white);
    }

    &:hover,
    &:focus {
        &::after {
            transform: translateY(-50%) scale(1.2);
        }
    }
}

.btn-icon {
    padding-right: $_forms-input-padding-h;

    &::after {
        transform-origin: center center;
        transform: translate(-50%, -50%);
        right: auto;
        left: 50%;
    }

    &:hover,
    &:focus {
        &::after {
            transform: translate(-50%, -50%) scale(1.2);
        }
    }
}

.btn-search,
.btn-x {
    @extend %btn;
}

.btn-search-xl {
    @extend %btn-xl;
}

.btn-arrow-left,
.btn-arrow-right {
    @extend %btn-outline;
    @include button-helper(transparent, $accent-blue, $accent-blue, $white, transparent, $light-blue, true);

    &::before {
        content: none;
    }

    &::after {
        width: $ui-icon-width;
        height: $ui-icon-width * 0.75;
    }
}

.btn-arrow-left {
    &::after {
        @include ico-arrow($accent-blue);
    }

    &[disabled] {
        &::after {
            @include ico-arrow($medium-blue);
        }

        &:hover::after {
            transform: translate(-50%, -50%);
        }
    }
}

.btn-arrow-right {
    &::after {
        @include ico-arrow($accent-blue, right);
    }

    &[disabled] {
        &::after {
            @include ico-arrow($medium-blue, right);
        }

        &:hover::after {
            transform: translate(-50%, -50%);
        }
    }
}

.btn-x {
    @extend %un-button;
    box-shadow: none;
    padding: $space $gap-sm;

    &::before {
        content: none;
    }

    &::after {
        @include ico-x($accent-blue);
    }

    &[disabled] {
        &::after {
            @include ico-x($medium-blue);
        }

        &:hover::after {
            transform: translate(-50%, -50%);
        }
    }
}

.btn-submenu,
.btn-menu {
    @extend %btn;
    @include button-helper($white, $light-blue, $primary-text, $primary-text, $white, $gray-med, false);
    box-shadow: none;

    &:hover,
    &:focus {
        color: $accent-green;
    }
}

.btn-submenu {
    height: $_forms-input-padding-h * 2;

    &::before {
        @include ico-hex-hole($primary-text);
    }

    &::after {
        @include ico-hex-cross($primary-text);
    }
}

.btn-menu {
    height: ($icon-button-size / 2) + ($space * 2);
    width: ($icon-button-size / 1.5) + ($space * 2);
    padding: $space;
    background: transparent;

    &::before,
    &::after {
        background: none;
        left: $space;
        right: $space;
        width: auto;
        height: auto;
        transform: none;
        top: $space;
        bottom: $space;
        border: $ui-icon-thickness solid $accent-blue;
        border-left: 0;
        border-right: 0;
        transform-origin: center center;
    }

    &::after {
        border-bottom: 0;
        height: 0;
    }

    &:active {
        background: $gray-lightest;
    }

    &[aria-expanded="false"] {
        &::before,
        &::after {
            border-color: $accent-blue;
        }

        &::after {
            top: 50%;
            transform: translateY(-50%);
        }
    }

    &[aria-expanded="true"] {
        &::before,
        &::after {
            border-color: $accent-green;
        }

        &::before {
            border-bottom: 0;
            transform: rotate(45deg);
            height: 0;
            top: 50%;
        }

        &::after {
            transform: rotate(-45deg);
            top: 50%;
        }
    }

    &:hover,
    &:focus {
        &[aria-expanded="false"] {
            &::after {
                transform: translateY(-50%);
            }
        }
    }
}
