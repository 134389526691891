﻿/// <reference path="../../main">

.block {
    @include responsive-context($vertical-margin-map) {
        padding-top: $responsive-variable;
    }

    &-full-bleed {
        & + & {
            padding-top: 0;
        }

        & + &-top {
            padding-top: 0;
        }
    }

    &-banner {
        @include responsive-context($vertical-margin-map) {
            padding-top: $responsive-variable / 2;
            padding-bottom: $responsive-variable / 2;

            &-short {
                @if $responsive-breakpoint == small {
                    padding-top: $responsive-variable;
                    padding-bottom: $responsive-variable;
                }
                @elseif $responsive-breakpoint == medium {
                    padding-top: $responsive-variable / 2;
                    padding-bottom: $responsive-variable / 2;
                }
                @else {
                    padding-top: $responsive-variable / 3;
                    padding-bottom: $responsive-variable / 3;
                }
            }

            &-tall {
                padding-top: $responsive-variable;
                padding-bottom: $responsive-variable;
            }
        }
    }

    &-mobile-indent {
        .rich-text ~ *,
        .header ~ * {
            @include responsive-context($standard-padding-map) {
                @if $responsive-breakpoint == medium {
                    padding-left: getColumnWidth(12, 1, false, $responsive-variable, true);
                }

                @if $responsive-breakpoint == small {
                    padding-left: getColumnWidth(12, 1, false, $responsive-variable, true);
                }
            }
        }
    }
}

.block-inner {
    @extend %standard-padding-h;
    max-width: $max-page-width;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
}

.header + * {
    @extend %large-top-margin;
}

.block-header {
    max-width: $max-line-length * 0.75;

    & + * {
        @extend %x-large-top-margin;
    }
}

// Adjusting for top margin when skipped from header
[data-blocks] {
    @include responsive-context($vertical-margin-map) {
        & > .block-full-bleed:first-child,
        & > .block-full-bleed-top:first-child {
            margin-top: -$responsive-variable;
        }

        & > .block-full-bleed:last-child {
            margin-bottom: -$responsive-variable;
        }
    }
}
