﻿/// <reference path="../../main">

.feature .split {
    &-inner {
        justify-content: center;

        @include media(medium) {
            display: flex;
            flex-direction: column-reverse;
        }
    }

    &-item {
        @include media-min(medium) {
            flex: 0 0 auto;
        }
    }

    &-text {
        padding-top: 0;
        padding-bottom: 0;
        display: block;
        flex: 1 1 auto;

        @include media-min(medium) {
            max-width: $max-content-width / 12 * 5 - ($max-gutter-width * 2);
        }
    }

    &-text-container {
        @extend %x-large-top-margin;
        width: auto;
        flex: 1 1 auto;
        display: flex;
        align-items: center;

        @include media-min(medium) {
            margin-top: 0;
        }
    }

    &-image-right &-text-inner {
        @supports(display: grid) {
            grid-column: 1 / span 5;
        }
    }

    &-image-left &-text-inner {
        @supports(display: grid) {
            grid-column: 2 / span 5;
        }
    }

    &-image-container {
        display: flex;

        @include responsive-context($standard-padding-map, $gutter-map, true) {
            @if $responsive-breakpoint == null or $responsive-breakpoint == x-large {
                padding-left: $responsive-variable / 2;
                padding-right: $responsive-variable / 2;
                width: getColumnWidth(12, 7, false, $responsive-variable-2, false);
                margin-left: $responsive-variable-2;
                margin-right: $responsive-variable-2;
            }

            @if $responsive-breakpoint == small {
                margin-left: $responsive-variable-2 / 2;
                margin-right: $responsive-variable-2 / 2;
            }
        }

        @include media(medium) {
            width: auto;
            height: auto;
            min-height: 0;
            padding-left: 0;
            padding-right: 0;
        }
    }

    &-image-inner {
        position: relative;
        height: 0;
        width: 100%;
        padding-bottom: 9 / 16 * 100%;
        min-height: 100%;
    }
}

// left and right alignment
.feature .split {
    @include media-min(medium) {
        // Image left
        &-image-left {
            .split-text {
                margin-left: 0;
                margin-right: auto;
                padding-left: $max-gutter-width;
                padding-left: var(--gutter);
            }

            .split-image {
                &-container {
                    padding-right: 0;
                    margin-left: auto;
                }
            }
        }
        // Image right
        &-image-right {
            .split-text {
                margin-right: 0;
                margin-left: auto;
                padding-right: $max-gutter-width;
                padding-right: var(--gutter);

                &-container {
                    display: flex;
                    justify-content: flex-end;
                }
            }

            .split-image {
                &-container {
                    padding-left: 0;
                    margin-right: auto;
                }
            }
        }
    }
}
