﻿/// <reference path="../../main">
$modal-prefix: 'adage-';
// STRUCTURE & LAYOUT
.#{$modal-prefix}modal {
    &-open {
        overflow: hidden;
    }

    &-wrapper {
        &[aria-hidden="true"] {
            visibility: hidden;
            opacity: 0;
        }
        background-color: rgba($white, .8);
        overflow-x: hidden;
        position: fixed;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        z-index: 150;
        transition: 0.3s ease all;
        overflow-y: auto;
    }

    &-overlay {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        &:focus {
            outline: none;
            box-shadow: none;
            border: none;
        }
    }

    &-dialog {
        position: relative;
        width: 100%;
        max-width: pxToRem(750px);
        margin: auto;
        top: $gap;
        display: flex;
        align-items: center;
        @include media(tablet) {
            width: 100%;
            bottom: auto;
        }
    }

    &-header {
        display: flex;
        justify-content: space-between;
        margin-bottom: $gap-sm;
    }

    &-inner {
        position: relative;
        background-color: $white;
        color: $white;
        padding: $gap;
        width: 100%;
    }
}

// STYLING
.#{$modal-prefix}modal {
    &-heading {
        @extend %h4;
    }

    &-wrapper {
        @include responsive-property('padding', $standard-padding-map);
    }

    &-inner {
        &-full-bleed {
            padding: 0;
        }
    }
}

// CLOSE BUTTON
.#{$modal-prefix}modal {
    &-close {
        &:after {
        }

        flex-shrink: 0;

        @include media(medium) {
            right: 0;
        }
    }
}
