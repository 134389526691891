﻿/// <reference path="../../main">

.pill {
    &[type="radio"],
    &[type="checkbox"] {
        & + label {
            @extend %tiny;
            text-align: center;
            padding: $space $gap;
            border: $_forms-border-width solid $accent-blue;
            background: map-get($pill-theme, 'watermark');
            color: map-get($pill-theme, 'accent');
            border-radius: 9999em;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            min-height: 0;
            margin-top: 0;

            &::before {
                content: none;
            }

            &::after {
                @extend %full-cover;
                content: '';
                opacity: 0;
                transition: $transition;
                transform: none;
                border-radius: 999em;
                width: 100%;
                height: 100%;
            }

            & > * {
                display: block;
                max-width: 100%;
            }

            &:hover::after {
                opacity: 0.1;
            }
        }

        &:checked + label {
            background: $white;
            border-color: map-get($pill-theme, 'accent-alt');
            color: map-get($pill-theme, 'background');

            &::after {
                content: none;
            }
        }

        &:checked:not(:focus) + label {
            box-shadow: 0 0 0 2px inset map-get($pill-theme, 'accent-alt');
        }

        &:hover + label {
            background: map-get($pill-theme, 'watermark');
        }

        &:checked:hover + label {
            background: map-get($pill-theme, 'accent');
        }
    }
}
