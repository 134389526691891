﻿/// <reference path="../../main">

$_label-after-margin: $space;
$_form-item-margin: $gap;
$_forms-max-input-width: $max-input-width;

label,
.label-style {
    @extend %h5;
    display: block;

    & + input,
    & + select,
    & + textarea {
        margin-top: $_label-after-margin;
    }
}

.label-style + div {
    margin-top: $_label-after-margin;
}

.error-message {
    @extend %error-message;
}

.form {
    &-container {
        display: flex;
        flex-wrap: wrap;
        max-width: $max-input-width;

        @include responsive-context($gutter-map, $gutter-map-v) {
            margin-right: -$responsive-variable;
            margin-bottom: -$responsive-variable-2;
        }
    }

    &-item {
        &,
        &-full,
        &-md {
            max-width: $_forms-max-input-width;
            flex: 1 1 auto;

            @include responsive-context($gutter-map, $gutter-map-v) {
                margin-bottom: $responsive-variable-2;
                margin-right: $responsive-variable;
                width: calc(100% - #{$responsive-variable});
            }
        }

        &-half,
        &-sm {
            max-width: $_forms-max-input-width;
            flex: 0 0 auto;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            @include responsive-context($gutter-map, $gutter-map-v) {
                margin-right: $responsive-variable;
                margin-bottom: $responsive-variable-2;
                width: calc(50% - #{$responsive-variable});
            }

            @include media(x-small) {
                width: calc(100% - #{$min-gutter-width});
            }
        }
    }

    &-hint {
        @extend %body;
        margin-top: $sliver;
    }
}

.inline-input-btn {
    display: flex;
    align-items: center;

    & > * {
        flex: 0 1 auto;

        &:first-child {
            width: auto;
            flex: 1 1 auto;
            margin-right: $gap;
        }
    }

    @at-root {
        label + &,
        .label-style + & {
            margin-top: $_label-after-margin;
        }
    }

    @include media(x-small) {
        display: block;

        & > * {
            margin-bottom: $space;
            width: 100%;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}

.field-validation-error {
    @extend %error-message;
}

// adjust for validation errors
.validation-summary-errors ~ * {
    .form-item {
        &-half,
        &-sm {
            justify-content: flex-start;
        }
    }
}