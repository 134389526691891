﻿/// <reference path="../../main">

$_stepper-input-size: 2rem;
$_stepper-button-size: 2rem;
$_stepper-spacing: $space;
$_stepper-button-ui-size: 0.75rem;
$_stepper-button-ui-thickness: 2px;

.stepper {
    display: flex;
    align-items: center;

    @at-root {
        label:not(.visually-hidden) + & {
            margin-top: $_label-after-margin;
        }
    }

    & &-input[type] {
        width: $_stepper-input-size;
        flex: 0 1 auto;
        text-align: center;
        align-self: stretch;
        line-height: 1;
        padding: 0;
        border-color: transparent;
        margin-left: $sliver;
        margin-right: $sliver;
        background: transparent;

        &:valid {
            box-shadow: none;
        }
    }

    &-btn {
        @extend %btn;
        width: $_stepper-button-size;
        height: $_stepper-button-size;
        border-radius: $common-border-radius;
        min-width: 0;
        flex: 0 0 auto;
        padding: 0;

        &-up,
        &-down {
            position: relative;
            background: $white;
            border: $_stepper-button-ui-thickness solid $gray-darkest;

            &::before,
            &::after {
                content: '';
                display: block;
                width: $_stepper-button-ui-size;
                height: $_stepper-button-ui-thickness;
                background: $gray-darkest;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                position: absolute;
            }

            &:hover,
            &:focus,
            &:active {
                &:not([disabled]) {
                    background: $gray-darkest;

                    &::before,
                    &::after {
                        background: $white;
                    }
                }
            }

            &:active:not([disabled]) {
                background: $secondary-text;
            }

            &:focus {
                box-shadow: none;
            }

            &[disabled] {
                border-color: $gray-med;

                &::before,
                &::after {
                    background-color: $gray-med;
                }
            }
        }

        &-up {
            &::after {
                transform-origin: center center;
                transform: translate(-50%, -50%) rotate(90deg);
            }
        }

        &-down {
            &::after {
                content: none;
            }
        }
    }
}
