﻿/// <reference path="../../main">

.skip-target {
    margin-top: 0;

    &-spaced {
        @include responsive-context($vertical-margin-map) {
            margin-top: $responsive-variable;
        }

        &-mobile {
            @include responsive-context($vertical-margin-map) {
                @if $responsive-breakpoint == medium or $responsive-breakpoint == small {
                    margin-top: $responsive-variable;
                }
            }

            @include media-min(medium-small) {
                margin-top: 0;
            }
        }
    }
}
