﻿/// <reference path="../../main">

.form-item {
    &-xl:not([type="radio"]):not([type="checkbox"]) {
        font-size: $_h5-font-size;
        font-size: var(--h5-font-size);
        line-height: $_h5-line-height;
        line-height: var(--h5-line-height);
        border-radius: $medium-border-radius;
        padding-top: $_forms-input-padding-v * 1.5;
        padding-bottom: $_forms-input-padding-v * 1.5;
    }
}