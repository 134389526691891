﻿/// <reference path="../../main">

.hex-list {
    @extend %x-large-top-margin;

    &-item {
        @extend %standard-top-margin;
        $self: &;

        &-inner {
            display: flex;
            align-items: center;
            justify-content: space-between;

            @include media-min(medium) {
                @include column-width(12, 10, false, false);
            }

            @include media-range(medium-small, medium) {
                @include column-width(12, 11, false, false);
            }
        }

        &-image,
        &-text {
            flex: 0 0 auto;
        }

        &-image {
            @include responsive-context($card-padding-map-h) {
                width: calc(40% - #{$responsive-variable / 2});
            }
        }

        &-text {
            @include responsive-context($card-padding-map-h) {
                width: calc(60% - #{$responsive-variable / 2});
            }

            display: flex;

            &-content {
                &::before {
                    @extend %full-cover;
                }
            }
        }

        &-num {
            flex: 0 0 auto;
            margin-top: -$sliver;

            @include responsive-context($gutter-map) {
                margin-right: getColumnWidth(12, 1, false, $responsive-variable, true);
            }
        }

        &-heading {
            color: $accent-green;
        }

        &:nth-child(even) {
            #{$self}-inner {
                flex-direction: row-reverse;
            }

            #{$self}-heading {
                color: $accent-blue;
            }

            #{$self}-num {
                @include ico-hex-vert($accent-blue, $modifier: 'thin');
                color: $accent-blue;
            }
        }
        // "Random" positioning
        &:nth-child(4n) &-inner {
            @include responsive-context($gutter-map) {
                margin-left: getColumnWidth(12, 1, false, $responsive-variable, true);
            }
        }

        &:nth-child(4n+1) &-inner {
            @include responsive-context($gutter-map) {
                margin-left: getColumnWidth(12, 1.5, false, $responsive-variable, true);
            }
        }

        &:nth-child(4n+2) &-inner {
            @include responsive-context($gutter-map) {
                margin-left: 0;
            }
        }

        &:nth-child(4n+3) &-inner {
            @include responsive-context($gutter-map) {
                margin-left: getColumnWidth(12, 2, false, $responsive-variable, true);
            }
        }
        @include media-range(medium-small, medium) {
            &:nth-child(odd) &-inner {
                margin-left: getColumnWidth(12, 1, false, map-get($gutter-map, medium), true);
            }
            
            &:nth-child(even) &-inner {
                margin-left: 0;
            }
        }
        // Mobile overrides
        @include media(medium-small) {
            &:nth-child(n) {
                #{$self}-inner {
                    margin-left: 0;
                    margin-right: 0;
                    display: block;
                    position: relative;
                }

                #{$self}-text {
                    width: 100%;
                    position: relative;
                    z-index: $z-bubble-overlay;

                    &-content {
                        position: relative;

                        & > * {
                            position: relative;
                        }

                        &::before {
                            content: '';
                            background: rgba($white, 0.9);
                            box-shadow: 0 0 4rem 4rem rgba($white, 0.9);
                            border-radius: 33%;
                            filter: blur(1rem);
                        }
                    }
                }

                #{$self}-num {
                    position: relative;
                }

                #{$self}-image {
                    position: absolute;
                    width: 45vh;
                    top: $gap-xl;
                    z-index: $z-bubble;
                }
            }

            &:nth-child(odd) {
                #{$self}-image {
                    left: 0;
                    transform: translateX(-40%);
                }

                #{$self}-text {
                    min-height: calc(45vh + #{$gap-xl});
                }
            }

            &:nth-child(even) {
                #{$self}-inner {
                    padding-top: $gap-xl;
                }

                #{$self}-image {
                    top: 0;
                    right: 0;
                    transform: translateX(40%);
                }

                #{$self}-text {
                    min-height: 45vh;
                }
            }

            &:nth-child(4n) &-image {
                transform: translateX(55%);
            }

            &:nth-child(4n + 1) &-image {
                transform: translateX(-45%);
            }

            &:nth-child(4n + 2) &-image {
                transform: translateX(65%);
            }

            &:nth-child(4n + 3) &-image {
                transform: translateX(-60%);
            }
        }
    }
}
