﻿/// <reference path="../main">

// FONT SIZES
%body {
    font-size: $_body-font-size;
    font-size: var(--body-font-size);
    line-height: $_body-line-height;
    line-height: var(--body-line-height);
}

%h1 {
    font-size: $_h1-font-size;
    font-size: var(--h1-font-size);
    line-height: $_h1-line-height;
    line-height: var(--h1-line-height);
    font-weight: $body-light;
    letter-spacing: -0.0125em;
}

%h2 {
    font-size: $_h2-font-size;
    font-size: var(--h2-font-size);
    line-height: $_h2-line-height;
    line-height: var(--h2-line-height);
    font-weight: $body-light;
    letter-spacing: -0.007em;
}

%h3 {
    font-size: $_h3-font-size;
    font-size: var(--h3-font-size);
    line-height: $_h3-line-height;
    line-height: var(--h3-line-height);
    font-weight: $body-light;
    letter-spacing: -0.008em;
}

%h4 {
    font-size: $_h4-font-size;
    font-size: var(--h4-font-size);
    line-height: $_h4-line-height;
    line-height: var(--h4-line-height);
    font-weight: $body-light;
    letter-spacing: -0.008em;
}

%h5 {
    font-size: $_h5-font-size;
    font-size: var(--h5-font-size);
    line-height: $_h5-line-height;
    line-height: var(--h5-line-height);
    font-weight: $body-regular;
    letter-spacing: -0.008em;
}

%small,
%h6,
%titling {
    font-size: $_small-font-size;
    font-size: var(--small-font-size);
    line-height: $_small-line-height;
    line-height: var(--small-line-height);
}

%h6,
%titling {
    letter-spacing: $letter-spacing-wide;
    font-weight: $body-bold;
    text-transform: uppercase;
}

%h6 {
    color: $accessible-teal;
}

%tiny {
    font-size: $_tiny-font-size;
    line-height: $_tiny-line-height;
    letter-spacing: 0.125em;
    font-weight: $body-bold;
    text-transform: uppercase;
}

%large {
    font-size: $_large-body-font-size;
    font-size: var(--large-body-font-size);
    line-height: $_large-body-line-height;
    line-height: var(--large-body-line-height);
    font-weight: $body-light;
}

%medium-large {
    font-size: $_medium-large-body-font-size;
    font-size: var(--med-large-body-font-size);
    line-height: $_medium-large-body-line-height;
    line-height: var(--med-large-body-line-height);
    font-weight: $body-light;
    letter-spacing: $letter-spacing-wide / 10 * -1;
}

%h1,
%h2,
%h3,
%h4,
%h5,
%h6 {
    @at-root {
        %responsive-top-margin,
        * + & {
            margin-top: $_typography-paragraph-spacing;
            margin-top: var(--text-margin);
        }
    }
}

%watermark {
    font-size: calc(6rem + 10vw);
    line-height: 0.9;
    color: $gray-lightest;
    font-weight: $body-medium;
    letter-spacing: -0.05em;
}

%x-large-top-margin {
    margin-top: $_typography-paragraph-spacing * 3;
    margin-top: calc(var(--text-margin) * 3);
}

%large-top-margin {
    margin-top: $_typography-paragraph-spacing * 2;
    margin-top: calc(var(--text-margin) * 2);
}

%small-top-margin {
    margin-top: $_typography-line-spacing;
    margin-top: var(--line-margin);
}

%x-small-top-margin {
    margin-top: $_typography-line-spacing / 2;
    margin-top: calc(var(--line-margin) / 2);
}

%x-large-bottom-margin {
    margin-bottom: $_typography-paragraph-spacing * 3;
    margin-bottom: calc(var(--text-margin) * 3);
}

%large-bottom-margin {
    margin-bottom: $_typography-paragraph-spacing * 2;
    margin-bottom: calc(var(--text-margin) * 2);
}

%small-bottom-margin {
    margin-bottom: $_typography-line-spacing;
    margin-bottom: var(--line-margin);
}

%x-small-bottom-margin {
    margin-bottom: $_typography-line-spacing / 2;
    margin-bottom: calc(var(--line-margin) / 2);
}

%bold {
    font-weight: $body-medium;
}
