﻿$black: #000000;
$white: #ffffff;

// Theme colors
$darkest-blue: #001227;
$dark-blue: #011f42;
$navy-blue: #001835;
$accent-blue: #0056b8;
$medium-dark-blue: #2C537E;
$dark-medium-blue: #313C50;
$mediumer-dark-blue: #4F5A6E;
$medium-blue: #7CA8DA;
$light-blue: #89B1DE;
$accent-green: #81bc00;
$accent-light: #cdcada;
$off-white: #f4f8fb;
$error-red: #a6192e;

// Hues in order of darkest to lightest
// I keep uncovering new inconsistent gray shades in the design so I'm sure this will keep changing
$grays: (#333333, #666666, #a0a0a0, #CDCADA, #EDEDED, #F4F8FB);

$gray-lightest: nth($grays, 6);
$gray-light: nth($grays, 5);
$gray-lightish: #D1D1D1;
$gray-med-light: nth($grays, 4);
$gray-med: nth($grays, 3);
$gray-dark: nth($grays, 2);
$gray-darkest: nth($grays, 1);

// UI colors
$primary-text: $dark-blue;
$secondary-text: $accent-blue;
$dark-gradient: linear-gradient(139.48deg, #00294F 0%, #001227 100%);
$dark-gradient-alt: linear-gradient(241.3deg, #003565 0%, #001835 100%);
$dark-gradient-vertical: linear-gradient(180deg, #001936 0%, #001227 100%);
$green-blue-gradient: linear-gradient(223.87deg, rgba(43,176,70,0.23) 0%, rgba(1,31,66,0) 54.13%, $dark-blue 100%);
$gray-blue-gradient: linear-gradient(223.87deg, rgba($light-blue, 0.23) 0%, rgba($gray-light, 0) 54.13%, $gray-light 100%);
$ui-invalid: $error-red;

// Color list for theming (BG COLOR, TEXT COLOR, ACCENT TEXT COLOR (h6 color), WATERMARK COLOR, ACCENT GRADIENT)
$colors: (
    'dark': (
        'background': $dark-gradient, 
        'background-alt': $dark-gradient-alt,
        'text': $white,
        'accent': $gray-med-light, 
        'accent-alt': $accent-green,
        'watermark': $dark-blue, 
        'gradient': $green-blue-gradient),
    'dark-solid': (
        'background': $navy-blue, 
        'text': $white,
        'accent': $gray-med-light, 
        'accent-alt': $accent-green,
        'watermark': $dark-blue, 
        'gradient': $green-blue-gradient),
    'medium': (
        'background': $medium-dark-blue,
        'text': $white,
        'accent': $gray-lightish,
        'accent-alt': $white,
        'watermark': $light-blue,
        'gradient': $gray-blue-gradient),
    'light': (
        'background': $gray-light,
        'text': $accent-blue,
        'accent': $accent-blue,
        'accent-alt': $accent-blue,
        'watermark': $gray-lightest,
        'gradient': $gray-blue-gradient)
);

// Assigning theme so reskinning is easier
$pill-theme: map-get($colors, 'dark-solid');