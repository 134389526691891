﻿/// <reference path="../../../main">

.filter {
    &-heading {
        &:nth-child(n) {
            color: $light-blue;
        }

        &-container {
            flex: 0 0 auto;

            @include media(medium) {
                transform-origin: left center;
                transform: rotate(90deg) translate(-50%, -50%);
                white-space: nowrap;
                position: absolute;
                left: $space;
                top: 50%;
            }
        }
    }

    &-container {
        @extend %standard-padding-h;
        overflow: hidden;
        position: relative;
        padding-right: 0;

        &::before {
            @extend %full-cover;
            content: '';
            left: 50%;
            background: $navy-blue;
        }
    }

    &-inner {
        border-radius: $lg-border-radius;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        overflow: hidden;
        position: relative;
        padding: $gap $gap-md;
        display: flex;
        justify-content: space-between;
        align-items: center;
        max-width: $max-page-width - $max-standard-pad;
        margin-left: auto;
        margin-right: auto;

        @include media(medium) {
            background: $dark-gradient-alt;
            padding-top: $gap-md;
            padding-bottom: $gap-md;
            padding-left: $gap-lg;
        }
    }

    &-list {
        margin-top: 0;

        @include media-min(medium) {
            flex: 1 1 auto;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        @include media(medium) {
            flex: 0 1 auto;
            max-width: 100%;
        }
    }

    &-item {
        @include media-min(large) {
            display: flex;
            align-items: center;

            label {
                white-space: nowrap;
            }
        }

        @include media-min(medium) {
            margin-top: 0;
            margin-left: $gap;
            flex: 1 1 auto;
        }

        @include media-range(medium, large) {
            display: block;
        }

        @include media(medium) {
            margin-top: $gap-sm;
            max-width: 100%;
        }
    }

    &-dropdown {
        &:nth-child(n) {
            width: auto;
            max-width: 100%;

            @include media-range(medium, large) {
                width: 100%;
            }

            @include media-min(large) {
                margin-top: 0;
                width: 75%;
                margin-left: $gap-sm;
            }
        }
    }
}
