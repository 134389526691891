﻿/// <reference path="../main">

// Layout helpers
%standard-padding-h {
    @include responsive-context($standard-padding-map) {
        padding-left: $responsive-variable;
        padding-right: $responsive-variable;
    }
}

%standard-padding-v {
    @include responsive-context($small-vertical-margin-map) {
        padding-top: $responsive-variable;
        padding-bottom: $responsive-variable;
    }
}

%standard-margin-v {
    @include responsive-context($vertical-margin-map) {
        margin-top: $responsive-variable;
        margin-bottom: $responsive-variable;
    }
}

%standard-top-margin {
    @include responsive-context($vertical-margin-map) {
        margin-top: $responsive-variable;
    }
}

%small-margin-v {
    @include responsive-context($small-vertical-margin-map) {
        margin-top: $responsive-variable;
        margin-bottom: $responsive-variable;
    }
}

%card-padding {
    @include responsive-context($card-padding-map-h, $card-padding-map-v) {
        padding: $responsive-variable-2 $responsive-variable;
    }
}

%card-padding-mini {
    @include responsive-context($card-padding-map-mini-h, $card-padding-map-mini-v) {
        padding: $responsive-variable-2 $responsive-variable;
    }
}

%max-total-width {
    max-width: $max-page-width;
    margin-left: auto;
    margin-right: auto;
}

%max-content-width {
    max-width: $max-content-width;
    margin-left: auto;
    margin-right: auto;
}

%responsive-column-gap {
    @include responsive-context($gutter-map) {
        column-gap: $responsive-variable;
    }
}

%responsive-grid-gap {
    @include responsive-context($gutter-map) {
        grid-gap: $responsive-variable;
    }
}

// Flexbox helpers
%flex-between {
    display: flex;
    justify-content: space-between;

    & > * {
        margin-top: 0;
        flex: 0 1 auto;
        
        &:first-child {
            margin-right: $space;
        }
    }
}

%flex-fiftyfifty {
    display: flex;
    flex-wrap: wrap;

    & > * {
        flex: 0 1 auto;
        max-width: 100%;
        margin-top: 0;

        @include responsive-context($gutter-map) {
            width: calc(50% - #{$responsive-variable / 2 + 0.125rem});
            margin-right: $responsive-variable;

            &:nth-child(even) {
                margin-right: 0;
            }

            &:nth-child(2) ~ * {
                margin-top: $responsive-variable;
            }
        }
    }
}

// Position/spacing helpers
%full-cover {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
}

// For items with anchors so they can be spaced from the top
%offset-top {
    padding-top: $nav-desktop-height + $gap;
    padding-top: calc(var(--nav-clearance) + #{$gap});
    margin-top: -$nav-desktop-height - $gap;
    margin-top: calc(var(--nav-clearance) * -1 - #{$gap});
}

%round {
    border-radius: 9999em;
}

%two-col-container {
    display: flex;
    align-items: flex-start;

    @include media(medium-small) {
        display: block;
    }
}

%two-col-column {
    flex: 0 1 auto;

    @include responsive-context($gutter-map) {
        width: calc(50% - #{$responsive-variable / 2});
        margin-right: $responsive-variable;
    }

    @include media(medium-small) {
        width: 100%;
        margin-right: 0;
        margin-bottom: $gap-lg;
    }

    &:last-child {
        margin-right: 0;
        margin-bottom: 0;
    }
}

%centered-ui-component {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

%two-column-text-content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    .rich-text-cta {
        @extend %h6;
        @extend %x-large-top-margin;
        padding-top: $_small-font-size * $_small-line-height;
        padding-top: calc(var(--small-font-size) * var(--small-line-height));
        flex: 0 0 auto;

        @include media(small) {
            display: none;
        }
    }

    .rich-text {
        flex: 0 1 auto;

        @include media-min(small) {
            margin-right: $max-gutter-width;
        }
    }
}