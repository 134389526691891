﻿/// <reference path="../../main">

.split {
    &-inner {
        @include media-min(medium) {
            display: flex;
        }

        [data-watermark]::before {
            left: 50%;
            top: 50%;
            max-width: 100%;
            max-height: 100%;
            overflow: hidden;
            white-space: normal;
            transform: translate(-50%, -50%);
        }
    }

    &-text {
        max-width: $max-content-width / 2;

        @include responsive-context($vertical-margin-map) {
            padding-top: $responsive-variable;
            padding-bottom: $responsive-variable;
        }

        @include responsive-context($standard-padding-map, $gutter-map) {
            @if $responsive-breakpoint == null or $responsive-variable == x-large {
                padding-left: ($responsive-variable-2 * 2);
                padding-right: ($responsive-variable-2 * 2);
            }
            @else {
                padding-left: $responsive-variable;
                padding-right: $responsive-variable;
            }
        }

        @include media-min(medium) {
            @supports(display: grid) {
                padding-left: 0;
                padding-right: 0;
                display: grid;
                grid-column-gap: var(--gutter);
            }
        }
    }

    &-image-right &-text-inner {
        @supports(display: grid) {
            grid-column: 1 / span 5;
        }
    }

    &-image-left &-text-inner {
        @supports(display: grid) {
            grid-column: 2 / span 5;
        }
    }

    &-image {
        @extend %full-bleed-image;
        border-radius: $common-border-radius;

        &-picture {
            @extend %full-bleed-image-inner;
        }

        &-container {
            @extend %full-bleed-image-container;
            border-radius: $common-border-radius;

            @include media(medium) {
                min-height: 100vw;
            }
        }
    }
}

// Left and right alignment
.split {
    @include responsive-context($standard-padding-map, $all-breakpoints: true) {
        &-image-left &-text-container {
            padding-right: $responsive-variable;
        }

        &-image-right &-text-container {
            padding-left: $responsive-variable;
        }

        @if $responsive-breakpoint == 'medium' or $responsive-breakpoint == 'small' {
            &-image-left &-text-container {
                padding-right: 0;
            }

            &-image-right &-text-container {
                padding-left: 0;
            }
        }
    }

    &-image-left &-image-container {
        @include media-min(medium) {
            order: -1;
        }
    }
}
