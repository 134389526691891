﻿/// <reference path="../../main">

picture {
    display: inline-block;
}

.card-padding {
    @extend %card-padding;
}

.card-padding-mini,
.box {
    @extend %card-padding-mini;
}

.flex-between {
    @extend %flex-between;
}

.flex-fiftyfifty {
    @extend %flex-fiftyfifty;
}

.full-width {
    max-width: none;
    width: 100%;
}

.two-col-container {
    @extend %two-col-container;
}

.two-col-column {
    @extend %two-col-column;
}

.space-top {
    @extend %responsive-top-margin;
}

.space-top-lg {
    @extend %large-top-margin;
}

.space-top-xl {
    @extend %x-large-top-margin;
}

.stack {
    & > * + * {
        @extend %large-top-margin;
    }
    
    &-sm > * + * {
        @extend %responsive-top-margin;
    }
    
    &-lg > * + * {
        @extend %x-large-top-margin;
    }

    &-xs > * + * {
        margin-top: $sliver;
    }

    &-flush > * + * {
        margin-top: 0;
    }
}

.two-col-text-content {
    @extend %two-column-text-content;
}