﻿/// <reference path="../../main">

.link-list {
    @extend %x-large-top-margin;
    list-style-type: none;
    padding-left: 0;
    position: relative;

    @include media-min(medium-small) {
        padding-top: $gap-lg;
        padding-bottom: $gap-lg;
    }

    &-block {
        @include media(medium-small) {
            padding-top: 0;
        }

        [data-watermark]::before {
            top: 0;
        }
    }

    &-banner {
        @include media-min(medium-small) {
            display: none;
        }

        @include responsive-context($vertical-margin-map) {
            margin-bottom: $responsive-variable * 0.75;
        }
    }

    &-item {
        $self: &;

        * {
            transition: $transition;
        }

        &-image {
            &-container {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 50%;
                width: 50%;
                background-repeat: no-repeat;
                background-size: cover;
                border-radius: $common-border-radius;
                overflow: hidden;
            }

            @supports (object-fit: cover) {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center center;

                &-container {
                    background-image: none !important;
                }
            }
        }

        &-line {
            @extend %line;
            flex: 0 0 auto;

            &::before {
                opacity: 0;
                transition: $transition;
            }
        }

        &-link {
            text-decoration: none;
            width: calc(50% - 6rem);
            align-items: center;
            padding-top: $_typography-paragraph-spacing;
            padding-bottom: $_typography-paragraph-spacing;
            padding-right: 0;
            position: relative;
            z-index: $z-expand-x;
            display: flex;
            color: $primary-text;

            &:hover,
            &:focus {
                #{$self}-line {
                    flex: 1 0 auto;

                    &::before {
                        opacity: 1;
                    }
                }

                #{$self}-link-inner {
                    flex: 1 1 auto;
                }

                #{$self}-title {
                    color: $accent-blue;
                }
            }

            &:focus {
                @include media-min(medium-small) {
                    box-shadow: none;
                }

                #{$self}-button {
                    @extend %focus-solid;
                }

                #{$self}-line {
                    @extend %green-line;
                }
            }

            &-inner {
                display: flex;
                align-items: center;
                position: relative;
                flex: 0 1 auto;
            }
        }

        &-title {
            display: inline;
            flex: 0 0 auto;

            @include media(large) {
                max-width: min-content;
            }
        }

        &-button {
            flex: 0 0 auto;
            position: absolute;
            left: 100%;
            top: 50%;
            transform: translateY(-50%);

            &-text {
                display: block;
                white-space: nowrap;
                transition-duration: 0s;
            }
        }

        &-link:not(:focus):not(:hover) &-button {
            background: transparent;
            border-color: transparent;
            box-shadow: none;
            margin-left: -$gap-lg * 2;

            &::before {
                @include ico-hex-hole($accessible-teal);
            }

            &::after {
                @include ico-hex-arrow($accessible-teal);
            }

            &-text {
                opacity: 0;
                width: 0;
                overflow: hidden;
            }
        }
    }
}

// Animations
.link-list-item {
    $self: &;

    &-image {
        &-container {
            // IE fallback
            opacity: 0;
        }

        @supports(object-fit: cover) {
            opacity: 0.25;

            &-container {
                opacity: 1;
                clip-path: inset(0 100% 0 0);
                background: $white;
            }
        }
    }

    &:first-child &-image {
        opacity: 1;

        &-container {
            clip-path: inset(0 0 0 0);
        }
    }

    &-link:hover,
    &-link:focus {
        & + * #{$self}-image {
            opacity: 1;
        }

        & + #{$self}-image-container {
            opacity: 1;
            clip-path: inset(0 0 0 0);
        }
    }
}

// Mobile
@include media(medium-small) {
    .link-list {
        &-item {
            &-link {
                width: 100%;
            }

            &-link:focus &-button {
                box-shadow: none;
            }

            &-line {
                opacity: 0;
            }

            &-title {
                max-width: 100%;
            }

            &-button {
                background: transparent;
                border-color: transparent;
                box-shadow: none;
                position: static;
                transform: none;
                margin-left: -$gap;

                &::before {
                    @include ico-hex-hole($accessible-teal);
                }

                &::after {
                    @include ico-hex-arrow($accessible-teal);
                }

                &-text {
                    opacity: 0;
                    width: 0;
                    overflow: hidden;
                }
            }

            &-image-container {
                display: none;
            }
        }
    }
}

@include media(x-small) {
    .link-list-item {
        &-line {
            display: none;
        }

        &-link {
            &-inner {
                flex: 1 1 auto;
                justify-content: space-between;
            }
        }

        &-button:nth-child(n):nth-child(n):nth-child(n) {
            margin-left: $gap-sm;
            padding-left: 0;
        }
    }
}
