﻿/// <reference path="../../../main">

$_image-ratio: 1 / 1.4;

.linear-slide {
    display: flex;

    @include responsive-context($gutter-map) {
        &-content,
        &-image-container {
            width: calc(50% - #{$responsive-variable / 2});
        }

        &-content {
            margin-right: $responsive-variable;
        }
    }

    @include media(large) {
        display: block;

        &-content,
        &-image-container {
            width: 100%;
        }

        &-content {
            margin-right: 0;
            margin-bottom: $gap-md;
        }
    }

    &-image {
        @extend %full-bleed-image;
        border-radius: $common-border-radius;

        &-picture {
            @extend %full-bleed-image-inner;
        }

        &-container {
            @extend %full-bleed-image-container;
            padding-bottom: $_image-ratio * 100% / 2;
            height: 0;
            min-height: 0;

            @include media(large) {
                padding-bottom: $_image-ratio * 100%;
            }
        }
    }
}
