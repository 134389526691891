﻿/// <reference path="../../main">

$two-col-breakpoint: "medium";
$one-col-breakpoint: "small";

.column {
    &-list {
        display: flex;
        flex-wrap: wrap;
    }

    &-item {
        flex: 0 1 auto;
        margin-top: 0;

        .block {
            padding-top: 0;
            padding-bottom: 0;
        }

        .block-inner {
            padding-left: 0;
            padding-right: 0;
        }

        [data-watermark]::before {
            content: none;
        }

        .block-mobile-indent .rich-text ~ *,
        .block-mobile-indent .header ~ * {
            padding-left: 0;
        }
    }

    @include responsive-context($gutter-map) {
        &-list {
            margin-right: -$responsive-variable;
            margin-bottom: -$responsive-variable;
        }

        &-item {
            margin-right: $responsive-variable;
            margin-bottom: $responsive-variable;
        }
    }
}

// Equal columns
// Note that if the gutter-map changes so that the values are different from null to x-large, this will need to be revised
.equal {
    @include responsive-context($gutter-map, null, true) {
        @if $responsive-breakpoint == null {
            &-4 .column-item {
                width: calc(25% - #{$responsive-variable});
            }

            &-3 .column-item {
                width: calc(#{$one-third} - #{$responsive-variable});
            }

            &-2 .column-item {
                width: calc(50% - #{$responsive-variable});
            }
        }

        @if $responsive-breakpoint == $two-col-breakpoint {
            &-4,
            &-3 {
                .column-item {
                    width: calc(50% - #{$responsive-variable});
                }
            }
        }

        @if $responsive-breakpoint == $one-col-breakpoint {
            &-4,
            &-3,
            &-2 {
                .column-item {
                    width: calc(100% - #{$responsive-variable});
                }
            }
        }
    }
}

// Layout Configurations
.layout {
    @include responsive-context($gutter-map, null, true) {
        @if $responsive-breakpoint == null {
            &-1-2 .column-item:nth-child(2n + 1),
            &-2-1 .column-item:nth-child(2n) {
                width: calc(#{$one-third} - #{$responsive-variable});
            }

            &-1-3 .column-item:nth-child(2n + 1),
            &-3-1 .column-item:nth-child(2n) {
                width: calc(25% - #{$responsive-variable});
            }

            &-1-2 .column-item:nth-child(2n),
            &-2-1 .column-item:nth-child(2n + 1) {
                width: calc(#{$one-third * 2} - #{$responsive-variable});
            }

            &-1-3 .column-item:nth-child(2n),
            &-3-1 .column-item:nth-child(2n + 1) {
                width: calc(75% - #{$responsive-variable});
            }
        }

        @if $responsive-breakpoint == $two-col-breakpoint {
            &-1-3 .column-item:nth-child(2n + 1),
            &-3-1 .column-item:nth-child(2n) {
                width: calc(#{$one-third} - #{$responsive-variable});
            }

            &-1-3 .column-item:nth-child(2n),
            &-3-1 .column-item:nth-child(2n + 1) {
                width: calc(#{$one-third * 2} - #{$responsive-variable});
            }
        }

        @if $responsive-breakpoint == $one-col-breakpoint {
            &-1-2,
            &-1-3,
            &-2-1,
            &-3-1 {
                .column-item:nth-child(n) {
                    width: calc(100% - #{$responsive-variable});
                }
            }
        }
    }
}
