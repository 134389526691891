﻿/// <reference path="../main">

%pre-focus {
    outline-style: solid;
    outline-color: transparent;
    outline-width: 1px;
    transition: $transition;
    border-radius: $common-border-radius;
    display: inline-block;
}

%focus {
    box-shadow: $white 0 0 0 2px, currentColor 0 0 0 4px;
}

%focus-solid {
    box-shadow: $accent-green 0 0 0 3px, $white 0 0 0 5px;
}