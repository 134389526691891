﻿/// <reference path="../../main">

$_expanded-trigger-width: 8.5rem;

.expand-trigger {
    $self: &;
    @extend %btn-base;
    @extend %btn-with-icon;
    @extend %tiny;
    @include button-helper($white, $accent-blue, $accent-blue, $white, $white, $light-blue, true);
    padding-right: $_forms-input-padding-h + $ui-indicator-size-md + $gap-sm;
    transition: $transition;
    width: $_expanded-trigger-width;

    &::before {
        content: none;
    }

    &::after {
        @include ico-plus-sign($accent-blue);
        width: $ui-indicator-size-md;
        height: $ui-indicator-size-md;
        right: $_forms-input-padding-h;
        transform-origin: center center;
        transition: $transition / 2;
    }

    &-button {
        @extend %un-button;
        text-decoration: none;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        max-width: none;
        font-weight: $body-light;
        color: $primary-text;

        &:focus {
            box-shadow: none;
        }
    }

    &-text {
        transition: $transition;
    }

    &-container {
        position: relative;
        transition: $transition;
        border-radius: 999em;
        box-shadow: $ui-box-shadow;
        overflow: hidden;
        width: $_expanded-trigger-width;
    }

    &-x {
        @extend %btn-base;
        @extend %btn-with-icon;
        @extend %tiny;
        @include button-helper($accent-blue, $medium-blue, $white, $white, $gray-lightest, $gray-med, false);
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        padding: $_button-padding-v;
        width: $ui-indicator-size-md + ($_forms-input-padding-h * 2);
        z-index: $z-expand-x;
        transition: $transition / 3;

        &::before {
            content: none;
        }

        &::after {
            @include ico-x($white);
            width: $ui-indicator-size-md;
            height: $ui-indicator-size-md;
            right: $_forms-input-padding-h;
            transform-origin: center center;
            transition: $transition / 2;
        }
    }

    &-button-sm {
        width: auto;

        #{$self}-container,
        #{$self} {
            width: auto;
        }

        #{$self},
        #{$self}-x {
            padding-left: 0;
            padding-right: 0;
            width: $ui-indicator-size-md + ($gap * 2);

            &::after {
                right: $gap;
                margin-right: -2px;
            }
        }

        #{$self} {
            border-color: $white;
            background: $navy-blue;

            &::after {
                @include ico-plus-sign($white);
            }
        }

        #{$self}-container,
        &[aria-expanded="true"] #{$self}-container {
            width: $ui-indicator-size-md + ($gap * 2);
        }
    }
}

// hover
.expand-trigger-button:hover .expand-trigger {
    &,
    &-x {
        &::after {
            transform: translateY(-50%) scale(1.3);
        }
    }
}

// toggling
[aria-expanded="true"] {
    .expand-trigger {
        overflow: hidden;
        width: $ui-indicator-size-md + ($_forms-input-padding-h * 2);

        &-container {
            width: $ui-indicator-size-md + ($_forms-input-padding-h * 2);
            transition: $transition;
        }

        &-text {
            opacity: 0;
        }
    }
}

[aria-expanded="false"] {
    .expand-trigger-x {
        opacity: 0;
    }
}

[aria-expanded]:focus {
    .expand-trigger-container {
        @extend %focus-solid;
    }
}