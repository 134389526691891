﻿/// <reference path="../../../main">

.linear {
    &-item {
        &-inner {
            display: flex;
            flex-direction: column;

            &::before {
                @extend %full-cover;
                content: '';
                background: radial-gradient(circle at center, rgba($light-blue, 0.1) 0%, rgba($light-blue, 0) 100%);
                opacity: 0;
                transition: $transition;

                @include responsive-context($card-padding-map-h) {
                    margin-left: $responsive-variable / 2 * -1;
                    margin-right: $responsive-variable / 2 * -1;
                    width: calc(100% + #{$responsive-variable});
                }
            }
        }

        &-preview {
            @extend %responsive-top-margin;

            &-text {
                @extend %responsive-top-margin;
                transition: $slower-transition;
            }
        }

        &-preheading {
            position: absolute;
            left: 0;
            top: -$gap;
            transition: $transition;

            &:nth-child(n) {
                color: $mediumer-dark-blue;
            }
        }

        &-heading {
            color: $medium-blue;
            transition: $transition;
            // Non responsive text
            font-size: $_h4-font-size;
            line-height: $_h4-line-height;
            font-weight: $body-regular;
        }

        &-expanded {
            transition: $transition;
            opacity: 0;
            visibility: hidden;
            height: 0;

            @include responsive-context($card-padding-map-h) {
                width: calc(45vw - #{$responsive-variable});
                margin-left: 5vw;
            }
        }
    }
}

// On state
// Must be duplicated to support hover in browsers that don't support focus-within
@mixin _stage-item-on-state() {
    $self: '.linear-item';

    &::before {
        opacity: 1;
    }

    #{$self}-preheading {
        color: $gray-lightest;
    }

    #{$self}-heading {
        color: $accent-green;
    }

    #{$self}-trigger {

        &::after {
            border-color: $accent-green;
        }

        &-indicator {
            &::before {
                opacity: 0;
            }

            &::after {
                opacity: 1;
            }
        }
    }
}

// Applying "on" state and styles for "open" state
.linear-item {
    $self: &;

    .splide:not(.is-active) &-inner:hover,
    .splide.is-active &.is-active &-inner {
        @include _stage-item-on-state;
    }

    &-inner:focus-within {
        @include _stage-item-on-state;
    }

    &.open {
        width: 50vw;
        transition: $slower-transition ease-out;
    }

    &.open &-preview-text {
        opacity: 0;
        visibility: hidden;
        height: 0;
    }

    &.open &-expanded {
        visibility: visible;
        opacity: 1;
        height: auto;
        transition-delay: $transition;
    }

    &.open &-inner {
        &::before {
            content: none;
        }
    }
}
