﻿/// <reference path="../../../main">

.linear {
    &-block {
        padding-top: $gap-xxl;
        padding-bottom: $gap-xxl;

        &::before {
            top: $gap-xxl * 2;
        }

        &::before {
            white-space: nowrap;
            transform: translate(-40%, -110%);
        }
    }

    &-heading {
        &-container {
            @extend %standard-padding-h;
            max-width: $max-content-width;
            margin-left: 0;
            margin-right: 0;
            margin-bottom: $gap-xxl;
        }
    }

    &-list {
        display: flex;
        transition: $transition;

        @include media-min(medium) {
            width: 100%;
        }
    }

    &-track {
        @extend %standard-padding-h;

        @include media(medium) {
            padding-left: 0;
            padding-right: 0;
        }
    }
}

// Basic styling for individual columns
.linear {
    &-item {
        flex: 1 0 auto;
        border-left: 2px solid $dark-medium-blue;
        position: relative;
        padding-top: $gap;
        padding-bottom: $gap;
        transition: $slower-transition ease-in;

        &::before,
        &::after {
            content: '';
            position: absolute;
            background: $navy-blue;
            left: -3px;
            right: 1px;
            top: 0;
            height: $gap;
        }

        &::before {
            background: linear-gradient(to bottom, $navy-blue 0%, rgba($navy-blue, 0) 100%);
        }

        &::after {
            background: linear-gradient(to top, $navy-blue 0%, rgba($navy-blue, 0) 100%);
            bottom: 0;
            top: auto;
        }

        &:last-child {
            border-right: 2px solid $dark-medium-blue;

            &::before,
            &::after {
                right: -3px;
            }
        }

        &-inner {
            position: relative;
        }

        @include responsive-context($card-padding-map-h) {
            padding-left: $responsive-variable / 2;
            padding-right: $responsive-variable / 2;
        }
    }
}
