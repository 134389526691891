﻿/// <reference path="../../main">

html {
    scroll-behavior: smooth;
    -webkit-text-size-adjust: 100%; /* Prevent font scaling in iOS landscape while allowing user zoom */

    @media (prefers-reduced-motion: true) {
        scroll-behavior: auto;
    }
}

body {
    display: flex;
    flex-direction: column;
    min-height: 100%;
    height: 0; // IE fix
    padding-top: $nav-desktop-height;
    transition: $transition padding;

    @include media($nav-breakpoint) {
        padding-top: $nav-mobile-height;
    }
}

header[role="banner"] {
    flex: 0 0 auto;
    z-index: $z-main-nav;
    width: 100%;
    background: $white;
    position: fixed;
    top: 0;
    transition: $transition height;

    @include ie {
        // it looks like crap in IE even with fallbacks so let's not bother making it fixed position
        position: absolute;
        left: 0;
        right: 0;
    }
}

main {
    flex: 1 0 auto;
    min-height: 1px; // IE fix
}

footer[role="contentinfo"] {
    @extend %standard-margin-v;
    margin-bottom: 0;
    flex: 0 1 auto;
}

.skip-link {
    background: $white;
    position: absolute;
    padding: $space;
    z-index: $z-skip-link;
    top: 0;
    left: 0;
    transform: translateY(-100%);

    &:focus {
        transform: none;
    }
}

.page {
    @extend %max-total-width;
    @extend %standard-padding-h;
    width: 100%;

    &-full {
        max-width: none;
        padding-left: 0;
        padding-right: 0;
    }
}

@import
'header-navigation/_header.scss',
'header-navigation/_desktop-header.scss',
'header-navigation/_mobile-header.scss'
;