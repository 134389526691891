﻿/// <reference path="../../main">

input[type="radio"] {
    & + label,
    & + input[type="hidden"] + label {
        &::before {
            border-radius: 50%;
        }

        &::after {
            background: $_radiocheck-foreground;
            border: 2px solid transparent;
            border-radius: 50%;
            transform: scale(0.4);
            top: $_radiocheck-top-offset;
            left: 0;
        }
    }

    &:hover,
    &:checked {
        & + label,
        & + input[type="hidden"] + label {
            &::after {
                background: $_radiocheck-accent;
            }
        }
    }
}

// SIZING
input[type="radio"] {
    & + label,
    & + input[type="hidden"] + label {
        &::after {
            width: $_radiocheck-size;
            height: $_radiocheck-size;
        }
    }
}
