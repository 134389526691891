﻿/// <reference path="../../main">

%form-element {
    /* minimum size for form element font-size to prevent iOS resizing is 16px */
    font-size: 1rem;
    line-height: $_forms-input-line-height;
    padding: $_forms-input-padding;
    width: 100%;
    max-width: $_forms-max-input-width;
    font-family: $body-font;
    border: $_forms-border-width solid $accessible-teal;
    color: $accessible-teal;
    background-position: right center;

    &[disabled] {
        cursor: not-allowed;
        opacity: 0.75;
    }

    &:focus,
    &:hover {
        border-color: $_forms-highlight-color;
        box-shadow: 0 0 0 1px $_forms-highlight-color inset;
    }

    &::placeholder {
        color: $accessible-teal;
        font-weight: $body-regular;
    }
}

input:not([type="radio"]):not([type="checkbox"]),
select,
textarea {
    @extend %form-element;  

    &[aria-invalid="true"],
    &.input-validation-error {
        @extend %invalid;
    }
}

select,
textarea {
    display: block;
}

select {
    @include ico-select-dropdown($medium-blue);
    background-color: transparent;
    -webkit-appearance: none;
    -moz-appearance: none;

    &::-ms-expand {
        display: none;
    }

    background-size: 1rem 1rem;
    background-position: right 1rem center;
    background-repeat: no-repeat;
    padding-right: $_forms-input-padding-h + 1rem;

    &:focus {
        @include ico-select-dropdown($_forms-highlight-color);
    }
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}