﻿/// <reference path="../../main">

.card {
    &-item {
        background: $white;
        border-radius: $lg-border-radius;
        box-shadow: $container-box-shadow;
        transition: $transition all;

        @include responsive-context($card-padding-map-mini-h, $card-padding-map-mini-v) {
            padding: $responsive-variable-2 $responsive-variable;
        }

        &[data-watermark]::before {
            left: 0;
            transform: none;
            -webkit-text-stroke: 3px $light-blue;
            color: $white;
            opacity: 0.1;
            white-space: nowrap;
        }

        &:not(.is-active) {
            opacity: 0.7;
        }

        &.is-active:not(.is-origin),
        &.is-target {
            @extend %bg-dark-solid;
            opacity: 1;

            &[data-watermark]::before {
                -webkit-text-stroke: 0;
            }

            .accent {
                color: $accent-green;
            }
        }

        @include media(small) {
            &:nth-child(n):nth-child(n) {
                opacity: 1;

                @include build-theme(map-get($colors, 'dark'));
            }
        }
    }

    &-index {
        @include responsive-context($card-padding-map-v) {
            margin-bottom: $responsive-variable;
        }
    }
}
