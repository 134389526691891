/// <reference path="../../main">

a,
.hyperlink {
    @extend %link;
}

.primary-link {
    @extend %primary-link;
    @include link-helper($secondary-text, $secondary-text, $secondary-text);
}

.strong-link {
    @extend %link;
    @extend %bold;
}

.img-link {
    display: block;
}

.link-icon {
    @extend %btn-with-icon;
    padding-right: calc(#{$gap-sm} + 1.5em);

    &::before,
    &::after {
        width: 1.5em;
        height: 1.5em;
        right: $sliver;
    }

    @each $color, $value in $colors {
        .theme-#{$color} & {
            &::before {
                @include ico-hex-hole(map-get($value, 'text'));
            }

            &::after {
                @include ico-hex-arrow(map-get($value, 'text'));
            }

            &.accent {
                &::before {
                    @include ico-hex-hole(map-get($value, 'accent'));
                }

                &::after {
                    @include ico-hex-arrow(map-get($value, 'accent'));
                }
            }
        }
    }
}

.link-svg {
    display: block;

    &:hover,
    &:focus {
        opacity: 0.5;
        text-decoration: none;
    }
}

a[href^="mailto:"] {
    word-break: break-word;
}

a[href^="tel:"] {
    white-space: nowrap;
}
