﻿/// <reference path="../../main">\
$_image-ratio: 5 / 4;

.card {
    &-image {
        @extend %full-bleed-image;
        border-radius: $common-border-radius;

        &-picture {
            @extend %full-bleed-image-inner;
        }

        &-container {
            @extend %full-bleed-image-container;
            padding-bottom: $_image-ratio * 100% / 2;
            height: 0;
            min-height: 0;
            margin-bottom: $gap-sm;
        }
    }

    .rich-text {

        [role="heading"] {
            @extend %h4;
        }

        &-inner {
            margin-top: calc(var(--text-margin) / 2)
        }

        &-cta {
            margin-top: var(--text-margin);
        }
    }
}
