﻿/// <reference path="../../main">

$_hero-scroll-icon-height: 2.5rem;

.hero {
    display: flex;
    margin-top: $gap-xxl;

    @include responsive-context($vertical-margin-map) {
        @if $responsive-breakpoint == small or $responsive-breakpoint == medium {
            min-height: calc(100vh - #{$nav-desktop-height + $gap-xxl + $gap-sm});
        }
        @else {
            min-height: calc(100vh - #{$nav-desktop-height + ($responsive-variable + $gap-xxl)});
        }
    }

    &-block {
        position: relative;
    }

    &-heading {
        @include responsive-context($standard-padding-map) {
            @if $responsive-breakpoint != small {
                padding-right: $responsive-variable / 2;
            }
            @else {
                padding-right: $responsive-variable;
            }
        }

        @include media-range(small, medium) {
            padding-right: map-get($standard-padding-map, medium);
        }

        &-container {
            @extend %standard-padding-h;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-start;
            flex: 1 1 auto;
            width: 50%;
            padding-right: $gap-md;

            @include media(medium) {
                width: auto;
                padding-right: 0;
            }

            &::before {
                content: ' ';
                display: block;

                @include media(medium) {
                    content: none;
                }
            }

            & > * {
                max-width: 100%;
            }
        }
    }


    &-image {
        &-container {
            flex: 0 0 auto;
            margin-right: 0;
            min-height: 25vw;

            @include responsive-context($gutter-map) {
                width: calc(50% - #{$responsive-variable / 2});
            }

            @include media(medium) {
                display: none;
            }
        }

        &-picture {
            border-bottom-left-radius: $common-border-radius;
            border-top-left-radius: $common-border-radius;
            overflow: hidden;
        }

        &-mobile {
            @extend %x-large-top-margin;

            @include media-min(medium) {
                display: none;
            }

            width: 100%;
            min-height: 40vw;

            @include media(small) {
                min-height: 50vw;
            }

            & > * {
                width: auto;

                @include responsive-context($standard-padding-map) {
                    left: getColumnWidth(12, 1, false, $responsive-variable, true);
                }
            }
        }
    }

    &-scroll {
        display: inline-block;
        padding: $space;
        margin-left: -$space;
        margin-top: $gap;

        &:hover &-icon,
        &:focus &-icon {
            animation: 1s bounce-down ease-in-out infinite;
        }

        &-icon {
            fill: $accent-green;
            height: $_hero-scroll-icon-height;
        }
    }
}
