﻿/// <reference path="../../main">

.job-result {
    background: $white;
    border-radius: $lg-border-radius;
    box-shadow: $container-box-shadow;
    margin-top: $gap-md;

    @include responsive-context($card-padding-map-mini-h, $card-padding-map-mini-v) {
        padding: $responsive-variable-2 $responsive-variable;
    }

    &:first-child {
        margin-top: 0;
    }

    &-list {
        margin-top: $gap-lg;
    }

    &-inner {
        @include media-min(medium-small) {
            display: flex;
            justify-content: space-between;
        }
    }

    &-info {
        flex: 0 1 auto;

        @include responsive-context($gutter-map) {
            margin-right: $responsive-variable;
        }

        @include media-min(medium) {
            @include column-width(12, 6);
        }

        @include media(medium) {
            flex: 1 1 auto;
        }

        @include media(small) {
            margin-right: 0;
        }
    }

    &-cta {
        @extend %large-top-margin;
        flex: 0 0 auto;

        @include media-min(medium-small) {
            margin-top: 0;
        }
    }

    &-details {
        @include media(smallish) {
            display: block;

            & > * {
                width: auto;
                margin-right: 0;
            }
        }
    }
}
