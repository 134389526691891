﻿/// <reference path="../../../main">

.keyword-hero {
    &-input {
        &-wrapper {
            position: relative;

            @include media(smallish) {
                &:nth-child(n) {
                    width: 100%;
                    margin-right: 0;
                }
            }
        }
    }

    &-fields {
        @include media(smallish) {
            justify-content: center;
            flex-wrap: wrap;
        }
    }

    &-search {
        @include media(smallish) {
            margin-top: $gap;
        }
    }

    &-x {
        position: absolute;
        top: 50%;
        right: $space;
        transform: translateY(-50%);
    }

    &-input:not([type="checkbox"]):not([type="radio"]) {
        max-width: 100%;
    }

    &-form {
        @extend %x-large-top-margin;
        width: 100%;
    }

    &-image-container {
        @include responsive-context($small-vertical-margin-map) {
            margin-top: -$responsive-variable;
        }

        min-height: 40vh;
    }

    &-scroll {
        &-container {
            @extend %standard-padding-h;
            @extend %large-top-margin;
        }
    }

    &-content {
        @extend %large-top-margin;
        width: 100%;

        @include responsive-context($standard-padding-map) {
            padding-right: $responsive-variable;
        }

        @include media-min(medium) {
            padding-right: 0;
            margin-top: 0;
        }
    }
}