﻿/// <reference path="../../main">

// Mixin for use with common theme defaults and if needed for responsive overrides where you can't @extend
// This doesn't cover all interactive scenarios or buttons, just use @extend %bg-dark, etc where possible.
@mixin build-theme($theme-values: map-get($colors, 'dark')) {
    background: map-get($theme-values, 'background');
    color: map-get($theme-values, 'text');

    .accent {
        color: map-get($theme-values, 'accent');
    }

    @if map-has-key($theme-values, 'accent-alt') {
        .accent-alt {
            color: map-get($theme-values, 'accent-alt');
        }

        .rich-text ul li::before {
            color: map-get($theme-values, 'accent-alt');
        }
    }

    .accent-border {
        border-color: rgba(map-get($theme-values, 'accent'), 0.5);
    }

    a:not([class*="btn"]) {
        &.accent {
            color: map-get($theme-values, 'accent');
        }

        &:focus {
            box-shadow: map-get($theme-values, 'watermark') 0 0 0 2px, currentColor 0 0 0 4px;
        }
    }

    @if map-has-key($theme-values, 'background-alt') {
        &.theme-alt {
            background: map-get($theme-values, 'background-alt');
        }
    }
}

// Assign theme values in loop through theme configs
@each $color, $value in $colors {
    .theme-#{$color},
    %bg-#{$color} {
        @include build-theme($value);

        %h6 {
            color: map-get($value, 'accent');
        }

        @if map-get($value, 'text') == $white {
            color: $white;

            a:not([class*="btn"]) {
                @extend %white-link;
            }

            @at-root {
                a:not([class*="btn"])#{&} {
                    @extend %white-link;
                }
            }

            .btn-outline {
                @include button-helper(transparent, $accent-blue, $white, $white, transparent, $light-blue, true);
                @extend %white-btn-outline;

                &:focus {
                    box-shadow: $accent-green 0 0 0 3px, $white 0 0 0 5px;
                }
            }

            ::selection {
                background: $white;
                color: $primary-text;
            }

            input, select, textarea {
                color: $white;
                border-color: $white;
            }

            select {
                @include ico-select-dropdown($white);

                option {
                    background: map-get($value, 'watermark');
                }
            }
        }
    }
}
