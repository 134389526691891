﻿/// <reference path="../../../main">

.map {
    &-controls {
        flex: 0 0 auto;
        background: $darkest-blue;
        display: flex;
        flex-direction: column;

        @include responsive-context($standard-padding-map, $gutter-map) {
            width: calc(#{$responsive-variable} + #{getColumnWidth(12, 5, false, $responsive-variable-2, false)});
        }

        @include media(medium-small) {
            width: 100%;
            max-height: none;
        }

        & > * {
            max-width: 100%;
        }
    }

    &-filters {
        padding: $gap-lg;

        @include responsive-context($standard-padding-map) {
            padding-left: $responsive-variable;
        }

        @include media(medium-small) {
            padding-left: $gap-lg;
        }

        &-list {
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
            max-width: max-content;
            margin-left: -$space;
            margin-right: -$space;
            margin-bottom: -$space;
        }

        &-item {
            flex: 0 1 auto;

            &:nth-child(n) {
                margin: $space;
            }
        }
    }

    &-locations {
        &-list {
            &-container {
                overflow: auto;
                flex: 1 1 auto;
                max-height: 26rem;

                @include media(medium-small) {
                    max-height: none;
                    overflow: visible;
                }
            }
        }

        &-item {
            margin-top: 0;
            margin-bottom: 0;
            background: $dark-gradient-vertical;
            padding: $gap $gap-lg;

            @include responsive-context($standard-padding-map) {
                padding-left: $responsive-variable;
            }

            @include media(medium-small) {
                padding-left: $gap-lg;

                &[data-toggle-hide="true"] {
                    visibility: hidden;
                    height: 0;
                    overflow: hidden;
                    padding-top: 0;
                    padding-bottom: 0;
                }
            }
        }

        &-panel {
            overflow: hidden;
            height: 0;
            width: 100%;
            transition: $transition height, $transition margin;
            position: relative;
            padding-top: $gap-sm;

            @include media(medium-small) {
                padding-left: ($ui-indicator-size-md * 1.5) + $gap;
            }

            &-container {
                position: relative;
            }

            &[aria-hidden="true"] {
                height: 0 !important;
                margin: 0 !important;
            }

            &[aria-hidden="false"] {
                height: auto;
            }

            & > * {
                width: 100%;
                position: relative;
            }
        }

        &-name {
            flex: 1 1 auto;
            position: relative;

            @include media(medium-small) {
                display: flex;
            }
        }

        &-heading {
            flex: 1 1 auto;
        }

        &-index {
            position: absolute;
            top: 0;

            @include responsive-context($standard-padding-map) {
                left: ($responsive-variable / 2 * -1) - ($ui-indicator-size-md * 0.75);
            }

            @include media(medium-small) {
                position: static;
                left: auto;
                flex: 0 0 auto;
                margin-right: $gap-sm;
            }

            &-active {
                @include ico-map-marker($accent-green);
                color: $primary-text;
            }
        }

        &-scroll {
            @extend %tiny;
            padding: $space;
            text-align: center;
            display: block;
            text-decoration: none;
            width: 100%;
            margin-top: 0;

            @at-root {
                button#{&} {
                    &:focus {
                        box-shadow: none;
                    }

                    &:focus,
                    &:hover {
                        background: $darkest-blue;
                    }
                }
            }
        }
    }
}
