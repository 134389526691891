﻿/// <reference path="../../main">

// STRUCTURE
.accordion {
    &-block {
        padding-bottom: $gap;
        margin-bottom: -$gap;
    }

    &-panel {
        overflow: hidden;
        transition: $transition height;
        max-width: 100%;

        &[aria-hidden="true"] {
            height: 0 !important;
        }

        &-content {
            width: 100%;
        }
    }

    &-inner {
        position: relative;
    }
}

// STYLES
.accordion {
    margin-left: auto;
    margin-right: auto;

    &-item {
        & + & {
            @extend %large-top-margin;
        }
    }

    &-inner {
        list-style-type: none;
        margin-left: 0;
        padding-left: 0;
    }

    &-panel {
        padding-top: 1px;
        padding-bottom: 1px;

        @include responsive-context($gutter-map, $all-breakpoints: true) {
            margin-left: $responsive-variable;
            margin-right: $responsive-variable;

            @if $responsive-breakpoint != small {
                padding-left: getColumnWidth(12, 4, false, $responsive-variable);
                padding-right: getColumnWidth(12, 1, false, $responsive-variable);
            }
        }

        @include media(medium-small) {
            padding-left: 0;
            padding-right: 0;
            margin-right: 0;
        }

        &-content {
            margin-bottom: $gap-sm;
            margin-top: $gap-sm;

            @include media(medium-small) {
                margin-bottom: 0;
            }
        }
    }
}

// Indicator/trigger
.accordion {
    &-trigger {
        @include responsive-context($gutter-map) {
            @if $responsive-breakpoint != small {
                padding-left: getColumnWidth(12, 1, false, $responsive-variable, true);
            }
        }

        @include media(medium-small) {
            padding-left: 0;
            display: block;
        }

        & > * {
            pointer-events: none;
            margin-top: 0;
            
            &:last-child {
                flex: 0 0 auto;
            }
        }

        &-line {
            @extend %line;
        }

        &-heading {
            display: inline-block;
            flex: 0 1 auto;

            @include media-min(medium-small) {
                max-width: 60%;
            }
        }

        &-mobile {
            margin-top: $_typography-paragraph-spacing;
            margin-top: var(--text-margin);
            display: flex;
        }
    }
}

// mobile/desktop
.accordion-trigger {
    &-mobile {
        @include media-min(medium-small) {
            display: none;
        }
    }

    &-desktop {
        @include media(medium-small) {
            display: none;
        }
    }
}

// focus and active states
.accordion-trigger {
    &[aria-expanded="true"] {
        color: $accent-blue;
        font-weight: $body-medium;
    }

    &[aria-expanded="true"] &-heading {
        font-weight: $body-regular;
    }

    &:focus &-heading {
        font-weight: $body-regular;
        color: $accent-green;
    }

    &:focus &-line {
        @extend %green-line;
    }
}
