﻿/// <reference path="../../main">

$_hero-scroll-icon-height: 2.5rem;

.search-hero {
    display: flex;
    overflow: hidden;
    min-height: calc(100vh - #{$nav-desktop-height} + #{$gap-lg * 2});
    max-width: $max-page-width;
    margin-left: auto;
    margin-right: auto;

    &-rect {
        min-height: calc(100vh - #{$nav-desktop-height});
    }

    &-image {
        height: auto;

        &-container {
            min-height: 0;
            flex: 0 1 auto;

            @include responsive-context($gutter-map) {
                width: calc(40% - #{$responsive-variable / 2});
            }
        }

        &-hex {
            clip-path: url(#hexPath);
            align-self: flex-start;
            height: 0;
            transform: translate(0, #{$gap-lg - $gap-sm});
            width: calc(100% + #{$gap-lg});
            padding-bottom: calc(100% + #{$gap-lg});

            &-inner {
                padding: $gap-lg;
            }

            &-container {
                width: calc(100vh - #{$nav-desktop-height});

                @include media(medium) {
                    height: auto;
                }
            }
        }
    }

    &-image-container:not(#{&}-hex-container) {
        @include media-min(medium) {
            margin-left: $gap;
            margin-top: $gap;
        }
    }

    &-heading-container {
        @include responsive-context($vertical-margin-map) {
            padding-top: $responsive-variable;
        }

        padding-bottom: $gap-lg * 3;
        padding-right: 0;
        justify-content: flex-end;
        flex: 0 1 auto;
        width: 60%;
        max-width: 50rem;
        min-width: 45rem;

        @include media(medium) {
            min-width: 0;
            max-width: 100%;
            width: 100%;
        }
    }

    &-heading-block {
        width: 100%;

        & > * {
            max-width: 50rem;
        }
    }

    &-form {
        display: flex;
        flex-wrap: wrap;

        @include responsive-context($standard-padding-map) {
            @if $responsive-breakpoint == medium or $responsive-breakpoint == small {
                margin-right: $responsive-variable;
            }
        }

        & > * {
            @include responsive-context($gutter-map, $gutter-map-v) {
                margin-right: $responsive-variable;
                margin-bottom: $responsive-variable;
            }

            align-self: flex-end;
        }

        @supports(display: grid) {
            display: grid;
            grid-template-columns: repeat(2, 1fr) min-content;
            grid-gap: $gap;

            &-item {
                margin-right: 0;
            }
        }

        &-item {
            @extend %flex-mobile-form-item;
        }

        &-spacer {
            @include media-min(medium-small) {
                display: none;
            }
        }

        @include media(medium-small) {
            display: block;
        }
    }
}
// Mobile
.search-hero {
    @include media(medium) {
        max-width: 100vw;
        min-height: calc(100vh - #{$nav-mobile-height});

        &-image {
            &-container {
                position: absolute;
                top: 0;
                right: 0;
                width: 100%;
                height: 100vh;
                max-width: 50vh;
                overflow: hidden;
            }

            &-hex {
                width: 100%;
                padding-bottom: 100%;
                transform: translate(20%, #{$gap-lg - $gap-sm});

                &-container {
                    @include ie {
                        height: 50vh;
                    }
                }
            }
        }

        &-heading {
            &-container {
                width: 100%;
                position: relative;
                z-index: $z-highlight-image;
                padding-bottom: $gap-lg;
            }

            &-block {
                background-image: linear-gradient(to bottom, rgba($white, 0) 0%, rgba($white, 0.9) 35%, rgba($white, 1) 50%, rgba($white, 1) 100%);
                padding-top: 20vh;
                margin-top: -20vh;
            }
        }
    }
}
