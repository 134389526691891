﻿/// <reference path="../../../sustainability">

$dark-green: #015846; // New branding

$teal: #00C7B1;
$light-teal: mix($teal, $white, 20%);
$accessible-teal: mix($teal, $black, 80%);

$blue: #3FA9F5;
$light-blue: mix($blue, $white, 20%);
$accessible-blue: mix($blue, $black, 80%);

$green: #54CE32;
$light-green: mix($green, $white, 20%);
$accessible-green: mix($green, $black, 80%);

$primary-text: $black;
$secondary-text: $accessible-teal;
$accent-blue: $accessible-blue;
$light-green: $green;
$well-green: $light-teal;
$accent-green: $accessible-green;
$lighter-green: rgba($light-green,0.25);
$medium-dark-blue: $dark-green;
$medium-blue: $accent-blue;
$navy-blue: $dark-green;
$darkest-blue: $dark-green;

$gray-light: #F2F2F2;
$gray-lightest: #F7F7F7;
$gray-lightest-b: #FAFAFA;
$gray-darkest: #333333;

// Color list for theming (BG COLOR, TEXT COLOR, ACCENT TEXT COLOR (h6 color), WATERMARK COLOR, ACCENT GRADIENT)
$dark-theme: (
    'background': $dark-green,
    'text': $white,
    'accent': $white, 
    'accent-alt': $light-teal,
    'heading': $white,
    'watermark': mix($dark-green, $white, 90%), 
    'gradient': $green-blue-gradient);

$colors: (
    'dark': $dark-theme,
    'dark-solid': $dark-theme,
    'medium': $dark-theme,
    'light': (
        'background': $light-teal,
        'text': $primary-text,
        'accent': $accessible-teal,
        'accent-alt': $accessible-teal,
        'heading': $dark-green,
        'watermark': mix($teal, $white, 10%),
        'gradient': $gray-blue-gradient)
);