﻿/// <reference path="../../../main">

.map-overlay {
    @extend %full-cover;
    z-index: $z-map-overlay;
    right: 10vw;
    width: calc(100% - 10vw);

    @include media(medium-small) {
        width: 100%;
        right: 0;
    }

    &::after {
        @extend %full-cover;
        content: '';
        width: 10vw;
        left: 100%;
        right: auto;
        background: linear-gradient(to right, rgba($gray-darkest, 0.7) 0%, rgba($gray-darkest, 0) 100%);

        @include media(medium-small) {
            content: none;
        }
    }

    &-inner {
        display: flex;
        height: 100%;

        @include media(medium) {
            flex-direction: column;

            & > * {
                max-width: 100%;
            }
        }
    }

    &-address,
    &-sidebar-footer,
    &-main {
        @include responsive-context($card-padding-map-h, $card-padding-map-v) {
            padding: $responsive-variable-2 ($responsive-variable * 2);
        }
    }

    &-address,
    &-sidebar-footer {
        padding-left: $gap-lg;
        padding-right: $gap-lg;
    }

    &-sidebar {
        @include column-width(12, 4, false, false);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex: 0 0 auto;

        @include media(medium) {
            width: 100%;
        }

        &-footer {
            padding-top: $gap-lg;
            padding-bottom: $gap-lg;
            background: $dark-blue;
            margin-top: 0;

            @include media(medium) {
                background: transparent;
                padding-top: 0;
            }

            @include media(medium-small) {
                margin-top: $gap-md;
            }

            & > * {
                display: block;
                width: 100%;
                text-align: center;
            }
        }
    }

    &-address {
        @include media-min(medium-small) {
            padding-left: $gap-lg * 2;
        }

        @include media(medium-small) {
            padding-bottom: 0;
        }

        .map-locations-index {
            left: -$gap-lg;
        }

        .map-address {
            @include media(medium-small) {
                display: none;
            }
        }

        &-heading {
            margin-top: ($nav-desktop-height + $gap-lg) * -1;
            padding-top: $nav-desktop-height + $gap-lg;
        }
    }


    &-main {
        max-height: 100%;
        overflow: auto;
        flex: 1 1 auto;

        @include media(medium) {
            max-height: none;
        }
    }

    &-x {
        position: absolute;
        right: $gap;
        top: $gap;
    }

    &-columns {
        display: flex;
        width: 100%;

        &-wrapper {
            @include responsive-context($card-padding-map-h) {
                margin-right: -$responsive-variable * 2;
                margin-left: -$responsive-variable * 2;
                padding-right: $responsive-variable * 2;
                padding-left: $responsive-variable * 2;
                max-width: calc(100% + #{$responsive-variable * 4});
            }

            @include media-min(medium-small) {
                margin-right: 0;
                margin-left: 0;
                padding-left: 0;
                padding-right: 0;
                max-width: 100%;
            }

            @include media(medium-small) {
                overflow-x: auto;
            }
        }
    }

    &-column {
        flex: 0 0 auto;
        margin-top: 0;

        @include responsive-context($gutter-map) {
            width: getColumnWidth(12, 4, false, $responsive-variable, false);
            margin-right: $responsive-variable;

            @include quantity(2) {
                width: getColumnWidth(12, 6, false, $responsive-variable, false);
            }

            @include quantity(1) {
                width: 100%;
            }
        }

        @include media(medium-small) {
            min-width: 12rem;

            &:first-child:last-child {
                min-width: 0;
                width: 100%;
            }
        }

        &:last-child {
            margin-right: 0;

            @include responsive-context($card-padding-map-h) {
                padding-right: $responsive-variable * 2;
                min-width: 12rem + ($responsive-variable * 2);
            }

            @include media-min(medium-small) {
                padding-right: 0;
                min-width: 0;
            }
        }

        &-heading {
            color: $light-blue;
            line-height: $_h5-line-height;
        }
    }

    &-bottom {
        border-top: 1px solid $accent-green;
        display: flex;

        @include responsive-context($card-padding-map-v) {
            margin-top: $responsive-variable;
            padding-top: $responsive-variable;
        }

        &-content {
            flex: 1 1 auto;

            @include responsive-context($card-padding-map-h) {
                margin-right: $responsive-variable;
            }
        }

        &-image {
            max-width: 15rem;
            flex: 0 0 auto;
            align-self: flex-start;
            margin-top: 0;

            @include responsive-context($card-padding-map-h) {
                width: calc(40% - #{$responsive-variable / 2});
            }
        }
    }
}
