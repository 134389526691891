﻿/// <reference path="../../main">

.banner {
    min-height: calc(100vh - #{$nav-desktop-height});
    display: flex;
    flex-direction: column;

    @include media($nav-breakpoint) {
        min-height: calc(100vh - #{$nav-mobile-height});
    }

    &-image {
        flex: 0 0 auto;
        @extend %full-bleed-image;

        &-picture {
            @extend %full-bleed-image-inner;
        }

        &-container {
            @extend %full-bleed-image-container;
            height: 40vh;
            width: 100%;
        }
    }

    &-scroll {
        padding-bottom: $gap;

        &-icon {
            fill: $accent-blue;
        }
    }

    &-content {
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        & > * {
            flex: 0 0 auto;
            max-width: 100%;
        }
    }
}
