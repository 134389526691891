﻿/// <reference path="../../../main">

.timeline-carousel {
    &-pagination {
        width: 100%;
        position: relative;
        border-bottom: 1px solid $medium-dark-blue;

        @include media(medium) {
            margin-left: -$gap-sm;
            margin-right: -$gap-sm;
            width: calc(100% + #{$gap-sm * 2});
            max-width: none;
        }
    }

    &-page {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translate(-50%, -50%);
        border-radius: 999em;
        height: $ui-indicator-size-lg * 1.2;

        @include ico-hex-dot($accent-green, 'vert-inner-solid');

        &::before {
            @include ico-hex-dot($accent-green, 'vert-outer');
            transform: scale(0.8);
        }

        &::after {
            @include ico-hex-dot($navy-blue, 'vert-inner', $medium-dark-blue);
            opacity: 1;
            transform: none;
        }

        &:hover::before {
            opacity: 0;
        }

        &:hover,
        &:focus {
            transform: translate(-50%, -50%);

            &::before,
            &::after {
                transform: scale(0.8);
            }

            &::after {
                opacity: 0;
            }
        }

        &:focus::before {
            opacity: 1;
        }


        &.is-active,
        &[aria-expanded="true"] {
            background-color: $navy-blue;

            &::before {
                transform: none;
            }

            &::after {
                transform: scale(0.8);
                opacity: 0;
            }
        }
    }
}
