﻿/// <reference path="../../../main">

@include media(medium) {
    .linear {
        &-item {
            padding-left: $gap;
            padding-right: $gap;

            &-inner {
                padding-left: $gap;
                padding-right: $gap;
                padding-top: $gap-lg;

                &::before {
                    content: none;
                }
            }

            &-trigger {
                display: none;

                &::after {
                    left: $ui-indicator-size-md - $gap;
                    right: -($gap * 3);
                    width: calc(100% + #{$gap * 4 - $ui-indicator-size-md} + 4px);
                }

                &-mobile {
                    display: block;
                    z-index: $z-bubble;
                }

                &-indicator {
                    top: $gap-lg;
                }
            }

            &-preview {
                margin-top: $_typography-paragraph-spacing * 2;

                &-text {
                    display: none;
                }
            }

            &-expanded {
                visibility: visible;
                opacity: 1;
                height: auto;
                margin-left: 0;
                width: 100%;
                max-width: none;
                transition: none;
                margin-top: $_typography-paragraph-spacing;
            }
        }
    }
}
