﻿@function generate-background-icon($color: $black, $type: 'invalid-icon', $color2: '') {
    $_url-color: currentColor;
    $_url-color-2: currentColor;

    @if $color != currentColor {
        @if str-index(#{$color}, '#') {
            $_url-color: '%23' + str-slice(#{$color}, 2, -1);
        }
        @else {
            $_url-color: $color;
        }
    }

    @if $color2 != currentColor {
        $_url-color-2: '%23' + str-slice(#{$color2}, 2, -1);
    }

    @if $type == 'hex-hole' {
        @return url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 33 18' style='enable-background:new 0 0 33 18;'%3E%3Cpath fill='#{$_url-color}' d='M14.6,0.2c0.2,0,0.5,0.1,0.6,0.3l0.1,0.1l2.4,4.2c0.2,0.4,0.1,0.8-0.3,1C17,6,16.6,6,16.4,5.7l-0.1-0.1l-2.2-3.8H6L1.9,9 L6,16.2h8.2l1.9-3.4c0.2-0.3,0.6-0.5,0.9-0.3l0.1,0c0.3,0.2,0.5,0.6,0.3,0.9l0,0.1l-2.1,3.7c-0.1,0.2-0.3,0.3-0.6,0.4l-0.1,0H5.5 c-0.2,0-0.5-0.1-0.6-0.3l-0.1-0.1l-4.5-8c-0.1-0.2-0.1-0.4,0-0.6l0-0.1l4.5-8C5,0.4,5.2,0.3,5.4,0.3l0.1,0H14.6z'/%3E%3C/svg%3E%0A");
    }
    @elseif $type == 'hex-arrow' {
        @return url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 33 18' style='enable-background:new 0 0 33 18;' xml:space='preserve'%3E%3Cpath fill='#{$_url-color}' d='M32.7,9.1v0.1l0,0l0,0l-0.1,0.1l0.1-0.1l-0.1,0.1L27.5,15l0,0c-0.3,0.2-0.7,0.2-1.2-0.1c-0.3-0.3-0.3-0.8,0-1.1l3.8-3.9 H13.9h-0.1c-0.3,0-0.6-0.3-0.6-0.7s0.3-0.7,0.6-0.7h0.1h16.2l-3.8-3.9c-0.3-0.3-0.3-0.8,0-1.1c0.5-0.3,0.9-0.3,1.2-0.1l0,0L32.6,9 L32.7,9.1L32.6,9L32.7,9.1L32.7,9.1L32.7,9.1'/%3E%3C/svg%3E%0A");
    }
    @elseif $type == 'hex-cross' {
        @return url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 33 18' style='enable-background:new 0 0 33 18;' xml:space='preserve'%3E%3Cpath fill='#{$_url-color}' d='M32.6,9.2c0,0.4-0.3,0.7-0.7,0.7h-5.1v4.8c0,0.4-0.3,0.7-0.7,0.7s-0.7-0.3-0.7-0.7V9.9H13.9c-0.4,0-0.7-0.3-0.7-0.7 s0.3-0.7,0.7-0.7h11.6V3.7c0-0.4,0.3-0.7,0.7-0.7s0.7,0.3,0.7,0.7v4.8h5.1C32.3,8.5,32.6,8.8,32.6,9.2z'/%3E%3C/svg%3E%0A");
    }
    @elseif $type == 'select-dropdown' {
        @return url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' height='16' width='16'%3E%3Cpath d='M1.33333 3.3335L0 4.66683L8 12.6643L16 4.66683L14.6702 3.3335L8 10.0002L1.33333 3.3335Z' fill='#{$_url-color}'/%3E%3C/svg%3E");
    }
    @elseif $type == 'hexagon-vert' {
        @return url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 10.5 12'%3E%3Cpath fill='#{$_url-color}' d='M10.3,9.2l-4.7,2.7C5.4,12,5.2,12,5,11.9L0.3,9.2C0.1,9.1,0,8.9,0,8.7l0-5.5c0-0.2,0.1-0.4,0.3-0.5L5,0.1 C5.2,0,5.4,0,5.5,0.1l4.7,2.7c0.2,0.1,0.3,0.3,0.3,0.5l0,5.5C10.5,8.9,10.4,9.1,10.3,9.2z M5.3,10.8l4.2-2.4l0-4.8L5.3,1.2L1.1,3.6 l0,4.8L5.3,10.8z'/%3E%3C/svg%3E%0A");
    }
    @elseif $type == 'hexagon-thin' {
        @return url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 11 13' style='enable-background:new 0 0 11 13;' xml:space='preserve'%3E%3Cpath fill='#{$_url-color}' d='M10.6,9.7l-4.7,2.7c-0.2,0.1-0.4,0.1-0.6,0L0.6,9.7C0.3,9.6,0.3,9.4,0.3,9.2V3.7c0-0.2,0.1-0.4,0.3-0.5l4.7-2.6 c0.2-0.1,0.4-0.1,0.5,0l4.7,2.7c0.2,0.1,0.3,0.3,0.3,0.5v5.5C10.8,9.4,10.6,9.6,10.6,9.7z M5.5,11.9l4.8-2.7V3.8L5.5,1.1L0.7,3.8 v5.4L5.5,11.9z'/%3E%3C/svg%3E%0A");
    }
    @elseif $type == 'hexagon-vert-solid' {
        @return url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 10.5 12' style='enable-background:new 0 0 10.5 12;'%3E%3Cpath fill='#{$_url-color}' d='M10.3,9.2l-4.7,2.7C5.4,12,5.2,12,5,11.9L0.3,9.2C0.1,9.1,0,8.9,0,8.7V3.2C0,3,0.1,2.8,0.3,2.7L5,0.1C5.2,0,5.4,0,5.5,0.1 l4.7,2.7c0.2,0.1,0.3,0.3,0.3,0.5v5.5C10.5,8.9,10.4,9.1,10.3,9.2z'/%3E%3C/svg%3E%0A");
    }
    @elseif $type == 'plus' {
        @return url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 14 14' style='enable-background:new 0 0 14 14;' xml:space='preserve'%3E%3Cpath fill='#{$_url-color}' d='M7,0.8c0.4,0,0.7,0.3,0.7,0.6v0.1v4.8h4.8c0.4,0,0.8,0.3,0.8,0.8c0,0.4-0.3,0.7-0.6,0.7 h-0.1H7.8v4.8c0,0.4-0.3,0.8-0.8,0.8c-0.4,0-0.7-0.3-0.7-0.6v-0.1V7.8H1.5C1.1,7.8,0.8,7.4,0.8,7s0.3-0.7,0.6-0.7h0.1h4.8V1.5 C6.3,1.1,6.6,0.8,7,0.8z'/%3E%3C/svg%3E");
    }
    @elseif $type == 'x' {
        @return url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 14 14' style='enable-background:new 0 0 14 14;' xml:space='preserve'%3E%3Cpath fill='#{$_url-color}' d='M11.4,2.6c0.3,0.3,0.3,0.7,0.1,1l-0.1,0.1L8.1,7l3.4,3.4c0.3,0.3,0.3,0.8,0,1.1 s-0.7,0.3-1,0.1l-0.1-0.1L7,8.1l-3.4,3.4c-0.3,0.3-0.8,0.3-1.1,0s-0.3-0.7-0.1-1l0.1-0.1L5.9,7L2.6,3.6c-0.3-0.3-0.3-0.8,0-1.1 s0.7-0.3,1-0.1l0.1,0.1L7,5.9l3.4-3.4C10.7,2.3,11.1,2.3,11.4,2.6z'/%3E%3C/svg%3E%0A");
    }
    @elseif $type == 'search' {
        @return url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 19 18'%3E%3Cpath fill='#{$_url-color}' d='M5.8,0.6C6,0.5,6.3,0.4,6.5,0.5l0,0l6.7,1.9c0.3,0.1,0.5,0.3,0.5,0.6l0,0l1.7,6.8 c0.1,0.3,0,0.6-0.2,0.7l0,0l-1.8,1.8l4.5,3.8c0.3,0.3,0.4,0.8,0.1,1.1c-0.3,0.3-0.7,0.4-1,0.2l-0.1-0.1l-4.6-3.9l-2.1,2 c-0.2,0.2-0.4,0.2-0.6,0.2l-0.1,0l-6.7-1.9c-0.3-0.1-0.5-0.3-0.5-0.6l0,0L0.5,6.3C0.4,6,0.5,5.7,0.7,5.5l0,0L5.8,0.6z M6.5,2.1 L2.1,6.3l1.5,5.9l5.9,1.7l4.4-4.2l-1.5-5.9L6.5,2.1z'/%3E%3C/svg%3E%0A");
    }
    @elseif $type == 'arrow-right' {
        @return url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 20 13'%3E%3Cpath fill='#{$_url-color}' d='M0.2,6.5c0-0.4,0.3-0.8,0.8-0.8h16l-3.8-3.8c-0.3-0.3-0.3-0.8,0-1.1c0.1-0.1,0.3-0.2,0.5-0.2s0.4,0.1,0.5,0.2l5.7,5.7 l-5.7,5.7c-0.3,0.3-0.8,0.3-1.1,0s-0.3-0.8,0-1.1l3.8-3.8h-16C0.5,7.3,0.2,6.9,0.2,6.5z'/%3E%3C/svg%3E%0A");
    }
    @elseif $type == 'arrow-left' {
        @return url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 20 13'%3E%3Cpath fill='#{$_url-color}' d='M19.8,6.5c0,0.4-0.3,0.8-0.8,0.8h-16l3.8,3.8c0.3,0.3,0.3,0.8,0,1.1c-0.1,0.1-0.3,0.2-0.5,0.2S6,12.3,5.8,12.2L0.2,6.5 l5.7-5.7c0.3-0.3,0.8-0.3,1.1,0s0.3,0.8,0,1.1L3.1,5.8h16C19.5,5.8,19.8,6.1,19.8,6.5z'/%3E%3C/svg%3E%0A");
    }
    @elseif $type == 'hex-dot-outline' {
        @return url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 31.1 29.1' %3E%3Cpath fill='#{$_url-color}' d='M13.4,10.7l4.3,0l2.2,3.8l-2.2,3.8l-4.3-0.1l-2.2-3.7L13.4,10.7 M12.8,9.2c-0.1,0-0.3,0.2-0.4,0.2l-2.8,4.8 c-0.1,0.2-0.1,0.4,0,0.6l2.7,4.7c0,0.1,0.2,0.2,0.4,0.3l5.4,0.1c0,0,0,0,0,0c0.2,0,0.4-0.1,0.5-0.3l2.8-4.8c0.1-0.2,0.1-0.4,0-0.6 l-2.7-4.7c-0.1-0.2-0.3-0.3-0.5-0.3L12.8,9.2L12.8,9.2z'/%3E%3C/svg%3E");
    }
    @elseif $type == 'hex-dot-solid' {
        @return url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 31.1 29.1' %3E%3Cpath fill='#{$_url-color}' d='M12.8,9.2l5.4,0c0.2,0,0.4,0.1,0.5,0.3l2.7,4.7c0.1,0.2,0.1,0.4,0,0.6l-2.8,4.8c-0.1,0.2-0.3,0.3-0.5,0.3l-5.4-0.1 c-0.2,0-0.4-0.1-0.4-0.3l-2.7-4.7c-0.1-0.2-0.1-0.4,0-0.6l2.8-4.8C12.5,9.4,12.7,9.3,12.8,9.2z'/%3E%3C/svg%3E%0A");
    }
    @elseif $type == 'hex-dot-frame' {
        @return url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 31.1 29.1' %3E%3Cpath fill='#{$_url-color}' d='M24.5,22.7l-2.1,3.6c0,0.1-0.2,0.3-0.6,0.3L9.1,26.3c-0.2,0-0.3-0.1-0.3-0.1c0,0,0,0,0,0L3,16.4l-2.1-0.6l6.4,11 c0.2,0.6,0.9,1,1.7,1l12.9,0.2c0,0,0.1,0,0.1,0c0.7,0,1.4-0.4,1.8-1.1l2.2-3.9L24.5,22.7z'/%3E%3Cpath fill='#{$_url-color}' d='M6.6,6.4l2.1-3.6C8.7,2.8,8.9,2.6,9,2.6l12.8,0c0.3,0,0.5,0.1,0.6,0.3l5.7,9.8l2.1,0.6L23.8,2.1c-0.4-0.6-1.1-1-1.9-1.1 l-13,0l-0.2,0C8.3,1.2,7.6,1.6,7.4,2L5.1,6L6.6,6.4z'/%3E%3C/svg%3E");
    }
    @elseif $type == 'map-marker' {
        @return url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 20 23' %3E%3Cpath fill='#{$_url-color}' d='M20,0v20h-7.1L10,23l-2.9-3H0V0h10H20z'/%3E%3C/svg%3E%0A")
    }
    @elseif $type == 'logo-watermark' {
        @return url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 848.3 843' %3E%3Cpath fill='#{$_url-color}' d='M424.1,0.4C187.1,0.4,0,187.5,0,418.3C0,642.8,180.9,830,405.4,836.2c-24.9-149.7-87.3-330.6-280.7-343 c-155.9-12.5,93.6-12.5,93.6-12.5c81.1,6.2,143.5,56.1,180.9,155.9v-6.2C374.2,486.9,299.4,331,124.7,318.5 C-31.2,306,218.3,306,218.3,306c99.8,6.2,174.6,87.3,199.6,249.5c24.9-162.2,99.8-243.2,199.6-249.5c0,0,249.5,0,93.6,12.5 C536.4,331,467.8,486.9,436.6,630.4c49.9-93.6,112.3-143.5,187.1-149.7c0,0,249.5,0,93.6,12.5c-193.3,12.5-255.7,193.3-280.7,349.3 c-6.2,0-12.5,0-18.7,0c0,0,0,0,6.2,0c230.8,0,424.1-187.1,424.1-417.9C848.2,187.5,654.9,0.4,424.1,0.4z M474,299.8 c0,12.5-37.4,124.7-49.9,118.5c-12.5,6.2-49.9-106-49.9-118.5c-12.5-68.6,43.7-174.6,49.9-168.4C430.4,125.2,486.5,231.2,474,299.8z '/%3E%3C/svg%3E%0A");
    }
    @elseif $type == 'hex-dot-vert-inner' {
        @return url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 31.1 36' %3E%3Cpath fill='#{$_url-color}' d='M15.6,29.3c-0.2,0-0.3,0-0.5-0.1L6.2,24c-0.3-0.2-0.5-0.5-0.5-0.9V12.8c0-0.4,0.2-0.7,0.5-0.9l8.9-5.2 c0.2-0.1,0.3-0.1,0.5-0.1s0.3,0,0.5,0.1l8.9,5.2c0.3,0.2,0.5,0.5,0.5,0.9v10.3c0,0.4-0.2,0.7-0.5,0.9l-8.9,5.2 C15.9,29.2,15.8,29.3,15.6,29.3z'/%3E%3Cpath fill='#{$_url-color-2}' d='M15.6,7.6l8.9,5.2v10.3l-8.9,5.2l-8.9-5.2V12.8L15.6,7.6 M15.6,5.6c-0.3,0-0.7,0.1-1,0.3l-8.9,5.2c-0.6,0.4-1,1-1,1.7v10.3 c0,0.7,0.4,1.4,1,1.7l8.9,5.2c0.3,0.2,0.7,0.3,1,0.3s0.7-0.1,1-0.3l8.9-5.2c0.6-0.4,1-1,1-1.7V12.8c0-0.7-0.4-1.4-1-1.7l-8.9-5.2 C16.3,5.7,15.9,5.6,15.6,5.6L15.6,5.6z'/%3E%3C/svg%3E%0A")
    }
    @elseif $type == 'hex-dot-vert-inner-solid' {
        @return url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 31.1 36' %3E%3Cpath fill='#{$_url-color}' d='M15.2,11.2l-5.3,3c-0.2,0.1-0.4,0.4-0.4,0.7V21c0,0.3,0.2,0.5,0.4,0.7l5.3,3c0.2,0.1,0.5,0.1,0.8,0l5.3-3 c0.2-0.1,0.4-0.4,0.4-0.7V15c0-0.3-0.2-0.5-0.4-0.7l-5.3-3C15.7,11.1,15.4,11.1,15.2,11.2z'/%3E%3C/svg%3E%0A")
    }
    @elseif $type == 'hex-dot-vert-outer' {
        @return url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 31.1 36' %3E%3Cpath fill='#{$_url-color}' d='M29.9,27.9L29.8,28l-13.1,7.2c-0.9,0.4-1.8,0.4-2.3,0l-13-7.5c-0.7-0.4-1.3-1.3-1.2-2.1l0-15.4c0-0.5,0.4-1.3,0.9-1.7 l0.2-0.1l13.1-7.5c0.8-0.5,1.6-0.4,2.5,0l13.1,7.6c0.7,0.4,1.3,1.3,1.2,2.1l0,15.3C31.1,26.7,30.6,27.5,29.9,27.9z M15.5,33.8 c0,0,0.2-0.1,0.4-0.1l13.1-7.3c0.4-0.2,0.4-0.5,0.4-0.6l0-15.3c-0.1-0.1,0-0.4-0.3-0.6L15.8,2.3c-0.2-0.1-0.5-0.1-0.8,0L2,9.9 c-0.1,0.1-0.2,0.4-0.2,0.4l0,15.3c0.1,0.1,0,0.4,0.3,0.6L15.5,33.8L15.5,33.8z'/%3E%3C/svg%3E%0A")
    }
    @elseif $type == 'chevron-up' {
        @return url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 12 8' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='#{$_url-color}' d='M10.2052 7.38233L6 3.17716L1.79484 7.38232L0.515003 6.10249L6 0.617491L11.485 6.10249L10.2052 7.38233Z'/%3E%3C/svg%3E%0A");
    }
}

// Individual mixins are mostly for easy use with intellisense. ico-prefix also helps.

@mixin ico-hex-hole($_bgcolor) {
    background-image: generate-background-icon($_bgcolor, 'hex-hole');
}

@mixin ico-hex-arrow($_bgcolor) {
    background-image: generate-background-icon($_bgcolor, 'hex-arrow');
}

@mixin ico-hex-cross($_bgcolor) {
    background-image: generate-background-icon($_bgcolor, 'hex-cross');
}

@mixin ico-select-dropdown($_bgcolor) {
    background-image: generate-background-icon($_bgcolor, 'select-dropdown');
}

@mixin ico-hex-vert($_bgcolor, $modifier: '') {
    @if $modifier == 'thin' {
        background-image: generate-background-icon($_bgcolor, 'hexagon-thin');
    }
    @elseif $modifier == 'solid' {
        background-image: generate-background-icon($_bgcolor, 'hexagon-vert-solid');
    }
    @else {
        background-image: generate-background-icon($_bgcolor, 'hexagon-vert');
    }
}

@mixin ico-plus-sign($_bgcolor) {
    background-image: generate-background-icon($_bgcolor, 'plus');
}

@mixin ico-x($_bgcolor) {
    background-image: generate-background-icon($_bgcolor, 'x');
}

@mixin ico-search($_bgcolor) {
    background-image: generate-background-icon($_bgcolor, 'search');
}

@mixin ico-arrow($_bgcolor, $direction: left) {
    @if($direction == left) {
        background-image: generate-background-icon($_bgcolor, 'arrow-left');
    }
    @else {
        background-image: generate-background-icon($_bgcolor, 'arrow-right');
    }
}

@mixin ico-hex-dot($_bgcolor, $type: 'outline', $_bgcolor2: '') {
    @if($type == 'outline') {
        background-image: generate-background-icon($_bgcolor, 'hex-dot-outline');
    }
    @elseif($type == 'solid') {
        background-image: generate-background-icon($_bgcolor, 'hex-dot-solid');
    }
    @elseif($type == 'frame') {
        background-image: generate-background-icon($_bgcolor, 'hex-dot-frame');
    }
    @elseif($type == 'vert-inner') {
        background-image: generate-background-icon($_bgcolor, 'hex-dot-vert-inner', $_bgcolor2);
    }
    @elseif($type == 'vert-inner-solid') {
        background-image: generate-background-icon($_bgcolor, 'hex-dot-vert-inner-solid');
    }
    @elseif($type == 'vert-outer') {
        background-image: generate-background-icon($_bgcolor, 'hex-dot-vert-outer');
    }
}

@mixin ico-map-marker($_bgcolor) {
    background-image: generate-background-icon($_bgcolor, 'map-marker');
}

@mixin logo-watermark($_bgcolor) {
    background-image: generate-background-icon($_bgcolor, 'logo-watermark');
}

@mixin ico-chevron($_bgcolor) {
    background-image: generate-background-icon($_bgcolor, 'chevron-up');
}
