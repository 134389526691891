﻿/// <reference path="../../../main">

.map {
    &-panel {
        flex: 1 1 auto;
        
        @include media(medium-small) {
            min-height: 50vw;
        }
    }
}