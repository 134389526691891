﻿/// <reference path="../../main">

body {
    font-family: $body-font;
    font-weight: $body-regular;
    --h1-font-size: #{$_h1-font-size};
    --h1-line-height: #{$_h1-line-height};
    --h2-font-size: #{$_h2-font-size};
    --h2-line-height: #{$_h2-line-height};
    --h3-font-size: #{$_h3-font-size};
    --h3-line-height: #{$_h3-line-height};
    --h4-font-size: #{$_h4-font-size};
    --h4-line-height: #{$_h4-line-height};
    --h5-font-size: #{$_h5-font-size};
    --h5-line-height: #{$_h5-line-height};
    --body-font-size: #{$_body-font-size};
    --body-line-height: #{$_body-line-height};
    --small-font-size: #{$_small-font-size};
    --small-line-height: #{$_small-line-height};
    --large-body-font-size: #{$_large-body-font-size};
    --large-body-line-height: #{$_large-body-line-height};
    --med-large-body-font-size: #{$_medium-large-body-font-size};
    --med-large-body-line-height: #{$_medium-large-body-line-height};
    --mobile-nav-font-size: #{$_mobile-nav-font-size};

    @include media(medium) {
        --h1-font-size: 3rem;
        --h2-font-size: 3rem;
        --h3-font-size: 2.25rem;
        --h4-font-size: 2rem;
        --h5-font-size: 1.25rem;
        --body-font-size: 0.9375rem;
        --body-line-height: 1.8;
        --small-font-size: 0.8125rem;
        --large-body-font-size: 1.5rem;
    }

    @include media(small) {
        --h1-font-size: 2.5rem;
        --h2-font-size: 2.75rem;
        --h3-font-size: 1.875rem;
        --h4-font-size: 1.5rem;
        --h5-font-size: 1.125rem;
        --body-font-size: 0.875rem;
        --small-font-size: 0.75rem;
    }

    --text-margin: #{$_typography-paragraph-spacing};
    --line-margin: #{$_typography-line-spacing};
}
