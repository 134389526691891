﻿/// <reference path="../../main">

$breakpoints: (
    x-large: $x-large-media-query-size,
    large : $large-media-query-size,
    medium: $medium-media-query-size,
    medium-small: $medium-small-media-query-size,
    small : $small-media-query-size,
    smallish: $smallish-media-query-size,
    x-small : $x-small-media-query-size
);

$standard-padding-map: (
    null        : $max-standard-pad,
    x-large     : 6rem,
    medium      : 4rem,
    small       : 2rem
);

$gutter-map: (
    null        : $max-gutter-width,
    x-large     : 2rem,
    medium      : 2rem,
    small       : $min-gutter-width
);

$gutter-map-v: (
    null        : 1.5rem,
    x-large     : 1.5rem,
    medium      : 1.5rem,
    small       : $min-gutter-height
);

$vertical-margin-map: (
    null        : $max-vertical-margin,
    x-large     : 9rem,
    medium      : 8rem,
    small       : 6rem
);

$medium-margin-map: (
    null        : 4rem,
    x-large     : 4rem,
    medium      : 4rem,
    small       : 3rem
);

$small-vertical-margin-map: (
    null        : 2rem,
    x-large     : 2rem,
    medium      : 2rem,
    small       : 1.5rem
);

$card-padding-map-h : (
    null        : 3rem,
    x-large     : 3rem,
    medium      : 3rem,
    small       : 2rem
);

$card-padding-map-v : (
    null        : 4rem,
    x-large     : 3rem,
    medium      : 3rem,
    small       : 3rem
);

$card-padding-map-mini-h : (
    null        : 2.5rem,
    x-large     : 2.5rem,
    medium      : 2.5rem,
    small       : 2.5rem
);

$card-padding-map-mini-v : $card-padding-map-mini-h;