﻿/// <reference path="../../../main">

.linear-timeline {
    &-track {
        padding-left: $gap-lg;
        padding-right: $gap-lg;
    }

    &-item {
        padding-left: $gap-sm;
        padding-right: $gap-sm;
        position: relative;

        @include media-min(medium) {
            width: 5.5rem;
            flex: 1 0 0;
        }

        &.open {
            @include media-min(medium) {
                flex: 1 0 50vw;
            }

            &::before {
                opacity: 1;
            }
        }

        &-expanded {
            height: auto;
            margin-left: 0;
            margin-top: $gap-lg;

            @include media-min(medium) {
                width: calc(50vw - #{$gap-sm * 2});
            }

            @include responsive-context($card-padding-map-h) {
                padding-left: $responsive-variable;
                padding-right: $responsive-variable;
            }

            @include media(medium) {
                padding-left: 0;
                padding-right: 0;
            }
        }

        &-inner {
            position: static;

            @include media(medium) {
                padding-top: 0;
            }

            &::before {
                background-image: linear-gradient(to bottom, rgba($light-blue, 0) 0%, rgba($light-blue, 0.1) 50%, rgba($light-blue, 0) 100%);
                margin-left: 0;
                margin-right: 0;
                width: 100%;
                opacity: 0;
                pointer-events: none;
            }
        }

        .splide:not(.is-active) &:not(.open) &-inner::before {
            opacity: 0;
        }

        .splide:not(.is-active) &.open &-inner::before {
            content: '';
            opacity: 1;
        }
    }

    &-pagination {
        margin-top: $gap-xl;

        @include media(medium) {
            display: none;
        }
    }

    &-item-slider {
        display: flex;
        flex-direction: column-reverse;

        & > * {
            max-width: 100%;
        }
    }

    &-item-track {
        margin-top: $gap-lg;
    }

    &-year-btn {
        $self: &;
        text-decoration: none;

        &.mobile &-inner {
            color: $white;
        }

        & &-inner {
            color: $medium-dark-blue;
            transition: $transition;
        }

        &::before {
            @extend %full-cover;
            content: '';
            cursor: pointer;
            z-index: $z-bubble;
        }

        &::after {
            @extend %full-cover;
            content: '';
            background-image: linear-gradient(to bottom, rgba($light-blue, 0) 0%, rgba($light-blue, 0.1) 50%, rgba($light-blue, 0) 100%);
            opacity: 0;
            transition: $transition;
            pointer-events: none;
        }

        &:hover,
        &:focus,
        &[aria-expanded="true"] {
            #{$self}-inner {
                color: $white;
            }

            &::after {
                opacity: 1;
            }
        }

        &:focus {
            box-shadow: none;
        }

        @include media-min(medium) {
            &.mobile {
                display: none;
            }
        }

        @include media(medium) {
            pointer-events: none;

            &.desktop {
                display: none;
            }
        }
    }

    &-item.open &-year-btn {
        &::before {
            content: none;
        }

        &::after {
            opacity: 0;
        }
    }
}
