﻿/// <reference path="../../../main">

.mobile.nav {
    @include media-min($nav-breakpoint) {
        display: none;
    }

    height: $nav-mobile-height;
    display: flex;

    &.collapsed {
        height: $nav-mobile-height-collapsed;
    }

    &-inner {
        background: $white;
        box-shadow: $container-box-shadow;
        flex: 1 1 auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: map-get($standard-padding-map, small);
        position: relative;
        z-index: $z-main-nav;
    }

    &-menu {
        @extend %full-cover;
        position: fixed;
        background: $white;
        top: 0;
        z-index: $z-main-nav - 1;
        padding: $gap-xl;
        padding-top: $nav-mobile-height + $gap-xl;
        padding-top: calc(var(--nav-clearance) + #{$gap-xl});
        // moving bottom padding to child to avoid a Firefox bug
        padding-bottom: 0;
        height: 100vh;
        overflow: auto;

        &[aria-hidden="true"] {
            display: none;
        }

        &-trigger {
            margin-right: -$space;
        }
    }

    &-main {
        padding-bottom: $gap-xl;

        &-0,
        &-1 {
            &-link {
                @extend %body;
                font-size: $_mobile-nav-font-size;
            }

            &-li {
                & + * {
                    @extend %large-top-margin;
                }
            }
        }

        &-0 {
            &-li {
                &-inner {
                    display: flex;
                    align-items: center;
                }
            }

            &-trigger {
                margin-left: $gap-sm;
            }
        }

        &-1 {
            &-submenu {
                transition: $transition height;
                overflow: hidden;

                &[aria-hidden="true"] {
                    height: 0 !important;
                }
            }

            &-ul {
                @extend %large-top-margin;
                padding-left: $gap-lg;
                margin-left: $space;
                border-left: 1px solid $light-blue;
            }
        }
    }
}
