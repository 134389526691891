﻿/// <reference path="../../main">

blockquote {
    font-weight: $body-medium;
    position: relative;
    quotes: "“" "”" "‘" "’";

    &,
    & + * {
        @extend %large-top-margin;
    }

    &::before,
    &::after {
        font-family: "Arial Unicode MS", sans-serif;
        line-height: 0.25;
        opacity: 0.1;
    }

    &::before {
        content: open-quote;
        position: absolute;
        left: 0;
        top: 0;
        transform: translate(-50%, 50%);
        font-size: 8em;
    }

    &::after {
        content: close-quote;
        position: absolute;
        transform: translate(0%, 75%);
        font-size: 5em;
    }
}
