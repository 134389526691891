﻿/// <reference path="../../main">
@mixin link-helper($_main-color: $secondary-text, $_hover-color: $accent-green, $_active-color: $accent-green, $_no-underline-hover: true) {
    color: $_main-color;

    &:hover,
    &:focus {
        color: $_hover-color;

        @if $_no-underline-hover == true {
            text-decoration: none;
        }
    }

    &:active {
        color: $_active-color;
    }
}