/// <reference path="../../main">

html {
    color: $primary-text;
}

body {
    font-size: $_body-font-size;
    font-size: var(--body-font-size);
    line-height: $_body-line-height;
    line-height: var(--body-line-height);
    font-family: $body-font;
    font-variant-numeric: lining-nums;
    font-kerning: normal;
    font-variant-ligatures: common-ligatures;
    font-feature-settings: "kern", "liga", "clig", "calt", "lnum";
    text-rendering: optimizeLegibility;
    text-rendering: geometricPrecision; // not supported by all

    ::selection {
        background: $accent-blue;
        color: $white;
    }
}

.body {
    @extend %body;
}

.small {
    @extend %small;
}

.tiny {
    @extend %tiny;
}

.large {
    @extend %large;
}

.medium-large {
    @extend %medium-large;
}

.medium {
    font-weight: $body-regular;
}

.bold,
strong {
    font-weight: $body-medium;
}

.extra-bold {
    font-weight: $body-bold;
}

.italic,
em {
    font-style: italic;
}

.uppercase {
    text-transform: uppercase;
}

.titling {
    @extend %titling;
    font-weight: $body-light;
}

.inline-block {
    display: inline-block;
}

.align-center {
    text-align: center;
}

.align-right {
    text-align: right;
}

.gray {
    color: $gray-dark;
}

.accent,
.accent-alt {
    color: $accent-blue;
}

p,
ul,
ol,
table {
   @extend %responsive-top-margin;

   &:first-child {
       margin-top: 0;
   }
}

/*  Apply silent classes to heading elements and equivalent classes at each level
    This equates to:
    h3, .h3-style { @extend %h3 } but without having to apply it to each level manually.
*/
@for $n from 1 through 6 {
    h#{$n},
    .h#{$n}-style,
    [role="heading"][aria-level="#{$n}"] {
        @extend %h#{$n};
    }
}

ul,
ol {
    padding-left: calc(0.66em + 6px);

    &.no-bullets,
    &.unstyled,
    &.form-list {
        list-style-type: none;
        padding-left: 0;
    }
}

ol {
    list-style-type: decimal;
}

ul {
    list-style-type: disc;
}

li {
    margin-top: $_typography-line-spacing;
    margin-top: var(--line-margin);

    &:first-child {
        margin-top: 0;
    }
}

.visually-hidden + * {
    margin-top: 0;
}

sup,
sub {
    font-size: calc(50% + 0.4rem);
    position: relative;
    vertical-align: baseline;
}

sup {
    top: -0.4em;
}

sub {
    top: 0.4em;
}

// preheading order helper
.has-preheading {
    display: flex;
    flex-direction: column;

    & > * {
        max-width: 100%;
        min-height: 1px;
    }

    .preheading {
        @extend %x-large-bottom-margin;
        order: -1;
        margin-top: 0;
    }

    .preheading-small {
        margin-bottom: $_typography-paragraph-spacing;
        margin-bottom: var(--text-margin);
    }
}