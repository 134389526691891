﻿/// <reference path="../../../main">

.linear-item-trigger {
    width: $ui-indicator-size-md;
    height: $ui-indicator-size-md;
    display: block;
    order: -1;
    width: 100%;

    &::before {
        @extend %full-cover;
        content: '';

        &:hover {
            cursor: pointer;
        }
    }

    &::after {
        content: '';
        border-top: 1px solid $medium-blue;
        display: block;
        position: relative;
        top: 0;
        left: $ui-indicator-size-md;
        width: calc(100% - #{$ui-indicator-size-md});
        margin-top: -1px;
        margin-left: -2px;
        transition: $transition;
    }

    &-indicator {
        width: $ui-indicator-size-md;
        height: $ui-indicator-size-md;
        position: absolute;
        left: 0;
        top: 0;

        &::before,
        &::after {
            @extend %full-cover;
            content: '';
            background-position: center center;
            background-repeat: no-repeat;
            background-size: contain;
            transition: $transition;
        }

        &::before {
            @include ico-hex-vert($medium-blue);
        }

        &::after {
            @include ico-hex-vert($accent-green, $modifier: 'solid');
            opacity: 0;
        }
    }

    &-mobile {
        display: none;
        pointer-events: none;

        &::before {
            content: none;
        }

        &::after {
            top: 50%;
        }
    }

    &:focus {
        box-shadow: none;

        &::after {
            border-color: $accent-green;
        }
    }

    &:focus &-indicator {
        &::before {
            opacity: 0;
        }

        &::after {
            opacity: 1;
        }
    }
}