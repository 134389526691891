﻿/// <reference path="../../main">
$logo-columns-map: ( null : 4, x-large : 4, medium-small : 2, small : 1 );

.logos-block {

    &-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin: -$gap-sm 0;
        padding: 0;

        &:first-child {
            margin: -$gap-sm 0;
        }
    }

    &-item {
        padding: $gap;
        display: flex;
        justify-content: center;
        list-style-type: none;
        margin: $gap-sm 0;
        flex: 0 0 50%;

        &:first-child {
            margin: $gap-sm 0;
        }

        @include media-min(tablet) {
            flex: 0 0 $one-third;
        }

        @include media-min(medium) {
            flex: 0 0 25%;
        }
    }
}

.logo {
    max-width: pxToRem(150px);
}
