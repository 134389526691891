﻿%full-bleed-image {
    display: none;

    @supports (object-fit: cover) {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center center;
        display: block;
    }
}

%full-bleed-image-inner {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
}

%full-bleed-image-container {
    background-size: cover;
    background-position: center center;
    position: relative;
    /*leaves background image loading on IE only*/
    @supports (object-fit: cover) {
        background-image: none !important;
    }
}
