﻿/// <reference path="../../main">

// The majority of styles for this block are handled on the carousel and card component styles

$_video-carousel-play-size: 117px;
$_video-play-icon-size: 2.75rem;

.video-carousel {
    &-play {
        @extend %centered-ui-component;
        height: $_video-carousel-play-size;
        width: $_video-carousel-play-size;
        z-index: $z-video-carousel-play;

        &-circle {
            @extend %round;
            opacity: 0.5;
            background-color: $accent-green;
            box-shadow: $ui-box-shadow;
            height: 100%;
            width: 100%;
            display: block;
        }

        &-triangle {
            @extend %centered-ui-component;
            width: 0;
            height: 0;
            border-top: $_video-play-icon-size/2 solid transparent;
            border-left: $_video-play-icon-size solid $white;
            border-bottom: $_video-play-icon-size/2 solid transparent;
            opacity: 0.8;
            transform: translate(-42%, -50%);
        }
    }

    &-item {
        $self: &;

        &.is-active:not(.is-origin) {
            #{$self}-button-thumb {
                padding-bottom: percentage(407 / 517);
                opacity: 1;
            }

            .video-carousel-item-description {
                opacity: 1;
            }
        }
    }

    &-item-button {
        @extend %un-button;
        display: block;
        text-decoration: none;
        width: 100%;

        &-frame {
            position: relative;
            width: 100%;
            overflow: hidden;
            height: 0;
            padding-bottom: percentage(407 / 517);
        }

        &-thumb {
            position: absolute;
            width: 100%;
            overflow: hidden;
            height: 0;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            padding-bottom: percentage(336 / 517);
            border-radius: $common-border-radius;
            box-shadow: 1px 2px 10px 0 rgba(23,20,34,0.15);
            transition: .2s ease all;
            opacity: .5;
        }

        img {
            @extend %cover-img;
            z-index: $z-video-carousel-image;
        }
    }

    &-item-icon {
        display: block;
        background-color: $accent-green;
        opacity: .5;
        height: $_video-carousel-play-size;
        width: $_video-carousel-play-size;
    }

    &-item-description {
        margin-top: $gap;
        text-align: center;
        opacity: 0;
        transition: $transition ease all;
    }

    &-item-iframe iframe, &-item-native video {
        width: 100%;
    }
}
