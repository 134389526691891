﻿/// <reference path="../../main">

.job-search {
    &-block {
        footer & {
            background: $navy-blue;
        }
    }

    &-form {
        display: flex;
        align-items: flex-end;

        @include media(medium-small) {
            display: block;
        }

        label {
            @include media-min(large) {
                white-space: nowrap;
            }
        }

        & > * {
            flex: 0 0 auto;
        }

        &-submit {
            text-align: right;
            flex: 1 1 auto;

            @include media-min(large) {
                align-self: center;
            }

            @include media(medium-small) {
                text-align: center;
            }
        }

        &-item {
            @extend %flex-mobile-form-item;
            @include column-width(12, 4, false, true);

            @include responsive-context($gutter-map-v) {
                margin-bottom: $responsive-variable;
            }

            @include media-min(medium-small) {
                margin-bottom: 0;
            }

            &:first-child {
                @include column-width(12, 5, false, true);
            }

            @include media(medium-small) {
                &:nth-child(n) {
                    width: auto;
                    margin-right: 0;
                }
            }

            @include media-min(large) {
                display: flex;
                align-items: center;
                flex-wrap: nowrap;

                * + *:nth-child(n) {
                    margin-top: 0;
                }
            }
        }
    }
}
