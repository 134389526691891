﻿/// <reference path="../../../main">

.linear {
    &-item-stage {
        @include media-min(medium) {
            width: 20%;
            min-width: 20%;
        }
    }
}

// Offsetting
.linear-item {
    $self: &;

    @include media-min(medium) {
        @for $i from 1 through 5 {
            &-stage:nth-child(#{$i}) {
                padding-top: $gap-lg * ($i + 1);

                & #{$self}-preheading {
                    top: $gap-lg * ($i + 1) * -1;
                }
            }
        }
    }
}