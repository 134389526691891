﻿/// <reference path="../../../main">

.nav {
    transition: $transition height;

    &-home {
        margin-right: $gap-sm;
        display: flex;
        align-items: center;
        flex: 0 0 auto;

        &-link {
            text-decoration: none;
            display: block;

            &-inner {
                display: flex;
                align-items: center;
            }
        }

        &-image {
            width: $logo-width;
            height: $logo-height;

            @include media($nav-breakpoint) {
                width: $logo-width-mobile;
                height: $logo-height-mobile;
            }
        }

        &-label {
            @extend %titling;
            margin-top: 0;
            margin-left: $gap-sm;
            padding-left: $gap-sm;
            display: block;
            border-left: 1px solid;
            font-weight: $body-regular;
        }
    }

    &-main {
        &-0,
        &-1 {
            &-ul {
                list-style-type: none;
                padding: 0;
                margin: 0;
            }

            &-li {
                margin-top: 0;
                margin-bottom: 0;
            }

            &-link {
                @extend %primary-link;
                @include link-helper($primary-text, $accessible-teal, $accessible-teal);
                font-weight: $body-medium;
                border-radius: 0;
                border: 2px solid transparent;
                border-left-width: 0;
                border-right-width: 0;

                &:focus {
                    box-shadow: none;
                    border-bottom-color: $accessible-teal;
                }
            }
        }
    }
}
