﻿/// <reference path="../main">

%cover-img {
    @extend %full-cover;
    height: auto;

    @supports(object-fit: cover) {
        height: 100%;
        object-fit: cover;
    }
}