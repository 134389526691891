﻿/// <reference path="../../../main">

.map {
    &-inner {
        position: relative;
        display: flex;

        @include media(medium-small) {
            flex-direction: column-reverse;
        }

        & > * {
            max-width: 100%;
        }
    }
}
