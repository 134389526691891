﻿/// <reference path="../../main">

$body-font: 'Roboto', Arial, sans-serif;

$body-light: 100; // "thin"
$body-regular: 300; // "light"
$body-medium: 500; // "medium"
$body-bold: 900; // "black"
$max-line-length: 50rem;
$letter-spacing-wide: 0.125em;

// Initial font sizing
$_typography-paragraph-spacing: 1em;
$_typography-line-spacing: 0.5em;

$_h1-font-size: 3.75rem;
$_h1-line-height: 1.2;
$_h2-font-size: 3.125rem;
$_h2-line-height: 1.2;
$_h3-font-size: 2.5rem;
$_h3-line-height: 1.2;
$_h4-font-size: 2.25rem;
$_h4-line-height: 1.22;
$_h5-font-size: 1.375rem;
$_h5-line-height: 1.3;
$_body-font-size: 1rem;
$_body-line-height: 1.75;
$_small-font-size: 0.875rem;
$_small-line-height: 1.66;
$_tiny-font-size: 0.7rem;
$_tiny-line-height: 1.5;
$_large-body-font-size: 1.875rem;
$_large-body-line-height: 1.5;
$_medium-large-body-font-size: 1.5rem;
$_medium-large-body-line-height: 1.5;

$_mobile-nav-font-size: 1.25rem;