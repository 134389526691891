﻿/// <reference path="../../main">

.rich-text {
    max-width: $max-line-length;

    &-inner {
        &:first-child {
            margin-top: 0;
        }
    }

    & > *:first-child {
        margin-top: 0;
    }

    *[style*="text-align: center;"] {
        margin-left: auto;
        margin-right: auto;
    }

    *[style*="text-align: right;"] {
        margin-right: 0;
        margin-left: auto;
    }

    &.centered {
        text-align: center;

        * {
            margin-left: auto;
            margin-right: auto;
        }
    }

    table {
        max-width: 100%;
    }

    p {
        max-width: 70ch;
    }

    ul {
        list-style-type: none;

        li {
            position: relative;
            padding-left: $_radiocheck-padding;

            &::before {
                content: '\2022';
                color: $accent-blue;
                display: inline-block;
                font-size: 2em;
                line-height: 1;
                position: absolute;
                left: -$_radiocheck-padding;
                top: 0.45em;
                transform: translateY(-50%);
            }
        }
    }

    &.full-width {
        max-width: none;

        p {
            max-width: none;
        }
    }

    &.horizontal-scroll &-inner {
        overflow-x: auto;
    }

    .two-column,
    .three-column {
        column-count: 2;
        column-gap: $gap-md;

        @include media(medium-small) {
            column-count: 1;
        }
    }

    .three-column {
        @include media-min(large) {
            column-count: 3;
        }
    }

    .well-green {
        color: $white;
        padding: $gap-sm;
        background-color: $dark-green;
    }

    .well-lightgreen {
        color: $dark-green;
        padding: $gap-sm;
        background-color: $well-green;
    }

    .well-white {
        color: $dark-green;
        padding: $gap-sm;
        background-color: $white;
    }
}
