﻿/// <reference path="../../main">

*:not(label) > input[type="checkbox"],
*:not(label) > input[type="radio"] {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: 0;
    overflow: hidden;
    opacity: 0;

    &:focus {
        outline: none;
        border: none;
        box-shadow: none;
    }
}

input[type="checkbox"],
input[type="radio"] {
    &:focus {
        & + label,
        & + input[type="hidden"] + label {
            @extend %focus-solid;
        }
    }

    & + label,
    & + input[type="hidden"] + label {
        @extend %pre-focus;
        font-size: 1rem;
        position: relative;
        display: inline-block;
        margin-left: 0;
        margin-top: 0;

        &::before {
            content: '';
            border: 2px solid $gray-darkest;
            display: inline-block;
            position: absolute;
            left: 0;
            top: $_radiocheck-top-offset;
        }
    }

    &:checked + label,
    &:checked + input[type="hidden"] + label {
        &::after {
            content: '';
            position: absolute;
            display: inline-block;
        }
    }

    &:hover,
    &:checked {
        & + label,
        & + input[type="hidden"] + label {
            &::before {
                border-color: $_radiocheck-accent;
            }
        }
    }

    &[disabled],
    &[aria-disabled="true"] {
        & + label {
            color: $gray-med;
            cursor: not-allowed;

            &::before {
                border-color: $gray-med;
            }
        }
    }
}

// SIZING
input[type="radio"],
input[type="checkbox"] {
    & + label,
    & + input[type="hidden"] + label {
        padding-left: $_radiocheck-size + $_radiocheck-padding;

        &::before {
            width: $_radiocheck-size;
            height: $_radiocheck-size;
        }
    }
}
