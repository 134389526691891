﻿/// <reference path="../main">

%icon {
    width: $ui-icon-width;
    height: $ui-icon-height;
    position: relative;

    &::before {
        @extend %full-cover;
        content: '';
    }
}

%visually-hidden {
    @include visually-hidden;
}

%indicator {
    &::before,
    &::after {
        content: '';
        display: inline-block;
        width: emToRem($ui-indicator-size-md);
        height: emToRem($ui-indicator-size-md);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
        vertical-align: middle;
        margin-bottom: 0.125em;
    }

    &::before {
        margin-right: $space;
    }

    &::after {
        margin-left: $space;
    }
}

%indicator-left {
    @extend %indicator;

    &::after {
        content: none;
    }
}

%indicator-right {
    @extend %indicator;

    &::before {
        content: none;
    }
}

%line {
    flex: 1 1 auto;
    border-top: 1px solid $light-blue;
    display: block;
    position: relative;

    @include responsive-context($gutter-map) {
        margin-left: $responsive-variable + $ui-indicator-size;
        margin-right: $responsive-variable;
    }

    &::before {
        content: '';
        display: block;
        width: $ui-indicator-size;
        height: $ui-indicator-size-md;
        position: absolute;
        left: -$ui-indicator-size;
        top: 50%;
        transform: translateY(-50%);
        background-repeat: no-repeat;
        background-size: contain;

        @include ico-hex-vert($light-blue);
    }
}

%green-line {
    border-color: $accent-green;

    &::before {
        @include ico-hex-vert($accent-green);
    }
}