/*
    Import reset first so it doesn't overwrite anything else
*/

@import 'reset.scss';

/*
    Utility partials:
    functions, mixins, variables and silent classes
    used alone, these partials produce no code when compiled, aside from custom properties in the :root
*/

@import '1-utility/index.scss';

@import '1b-patterns/index.scss';

/*
    Global partials:
    typography, forms, buttons
    base styles used across the site
*/

@import '2-global/index.scss';

/*
    Layout partials:
    all block, component, page specific css used on not necessarily every page
*/

@import '3-layout/index.scss';