﻿/// <reference path="../../../main">

.job-detail {
    &-container {
        --line-margin: 1em;
        --text-margin: 1.5em;
    }

    &-content {
        @extend %standard-top-margin;
    }

    &-posting {
        @include responsive-context($gutter-map) {
            margin-left: getColumnWidth(12, 1, false, $responsive-variable, true);
        }

        @include media(small) {
            margin-left: 0;
        }
    }

    &-us {
        b,
        strong {
            @extend %large;
            color: $accent-blue;
            font-weight: $body-regular;
            display: block;
            margin-top: $_typography-paragraph-spacing;
            margin-top: var(--text-margin);
            margin-bottom: 0.5em;

            @include responsive-context($gutter-map) {
                margin-left: -$responsive-variable;
            }

            @include media(small) {
                margin-left: 0;
            }
        }
    }

    &-uk {
        h2 {
            @extend %large;
            color: $accent-blue;
            font-weight: $body-regular;

            @include responsive-context($gutter-map) {
                margin-left: -$responsive-variable;
            }

            @include media(small) {
                margin-left: 0;
            }
        }

        b,
        strong {
            @extend %medium-large;
            color: $accent-blue;
            font-weight: $body-regular;
            display: block;
            margin-top: $_typography-paragraph-spacing;
            margin-top: var(--text-margin);
            margin-bottom: 0.5em;
        }
    }
}
