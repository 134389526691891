﻿/// <reference path="../../main">

@mixin button-helper($_color-1: $secondary-text, $_active-bg: $medium-blue, $_text-color: $white, $_active-color: $white, $_disabled-color-1: $gray-lightest, $_disabled-color-2: $gray-med, $_border: false, $_update-bg-on-hover: false) {
    background-color: $_color-1;
    color: $_text-color;

    @if $_border == true {
        border: $_forms-border-width solid $_text-color;
    }
    @else {
        border: $_forms-border-width solid $_color-1;
    }

    &[disabled] {
        color: $_disabled-color-2;
        background-image: none;
        background-color: $_disabled-color-1;

        @if $_border == true {
            border: $_forms-border-width solid $_disabled-color-2;
        }
        @else {
            border: $_forms-border-width solid $_disabled-color-1;
        }
    }

    &:hover,
    &:focus {
        color: $_text-color;

        @if $_update-bg-on-hover == true {
            color: $_active-color;
            background-color: $_active-bg;
        }

        &[disabled] {
            color: $_disabled-color-2;
        }
    }

    &:active {
        background: $_active-bg;
        color: $_active-color;
        border-color: $_active-bg;
    }
}