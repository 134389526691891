﻿/// <reference path="../../main">

.svg-icon {
    @extend %icon;
    fill: currentColor;
    vertical-align: baseline;
    display: inline-block;
    // magic number based on where the svgs lie
    top: $svg-vertical-adjust;
}

.svg-img {
    @extend %icon;
    width: 100%;
    height: 100%;
}

.rotate-180 {
    transform: rotate(180deg);
}

.visually-hidden {
    @extend %visually-hidden;
}

.hide {
    display: none !important;
    visibility: hidden !important;
}

[hidden] {
    display: none !important;
    visibility: hidden !important;
}

hr {
    height: 1px;
    background-color: $gray-light;
    border: 0;
}

.hex-num,
.marker-num {
    @extend %titling;
    letter-spacing: 0;
    line-height: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;

    @include media(medium) {
        font-size: $_tiny-font-size;
    }
}

.hex-num {
    @include ico-hex-vert($accent-green, $modifier: 'thin');
    color: $accent-green;
    // this design doesn't really follow existing patterns
    width: 3.25rem;
    height: 3.25rem;

    @include media(medium) {
        width: 2.5rem;
        height: 2.5rem;
    }
}

.marker-num {
    @include ico-map-marker($accent-blue);
    color: $white;
    width: $ui-indicator-size-md * 1.5;
    height: $ui-indicator-size-md * 1.5;
    padding-bottom: $sliver / 2;
}

// Circle with a background image fallback in IE and a regular img tag for browsers that support object-position
.circle-image {
    width: 100%;
    height: 0;
    padding-bottom: 100%;
    border-radius: 999em;
    overflow: hidden;
    position: relative;
    background: no-repeat center center;
    background-size: cover;

    &-img {
        display: none;
    }

    @supports(object-fit: cover) {
        background-image: none !important;

        &-img {
            display: block;
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            object-fit: cover;
            width: 100%;
            height: 100%;
            object-position: center center;
        }
    }
}

// for use to make sure alignment is right while working
.grid-guide {
    display: grid;
    grid-template-columns: repeat(12, minmax(0, 1fr));
    grid-column-gap: var(--gutter);

    & > * {
        padding: $space 0;
        background: $black;
    }
}

// Focal point alignment options, for sustainability home-banner but for use wherever needed
$_h-align: 'left', 'center', 'right';
$_v-align: 'top', 'center', 'bottom';

@each $_h in $_h-align {
    @each $_v in $_v-align {
        .focal-point-#{$_h} {
            &-#{$_v} {
                background-position: #{$_h} #{$_v};

                img {
                    object-position: #{$_h} #{$_v};
                }
            }
        }
    }
}
