@function strip-unit($number) {
  @if (type-of($number) == "number") and not unitless($number) {
    @return $number / ($number * 0 + 1);
  }

  @return $number;
}

@function sqrt($r) {
  $x0: 1;
  $x1: $x0;

  @for $i from 1 through 10 {
    $x1: $x0 - ($x0 * $x0 - abs($r)) / (2 * $x0);
    $x0: $x1;
  }

  @return $x1;
}

@function hypotenuse($side1, $side2) {
  $side1unitless: strip-unit($side1);
  $side2unitless: strip-unit($side2);
  $units: $side1 / $side1unitless;
  $hypSquared: ($side1unitless * $side1unitless) + ($side2unitless * $side2unitless);

  @return sqrt($hypSquared) * $units;
}

@function ratioToPercent($width: 16, $height: 9) {
    @return $height / $width * 100%;
}

@function pxToRem($size) {
    $remSizeUnitless: strip-unit($size) / 16;

    @return $remSizeUnitless * 1rem;
}

@function pxToEm($size) {
    $remSizeUnitless: strip-unit($size) / 16;

    @return $remSizeUnitless * 1em;
}

@function remToEm($size) {
    @return strip-unit($size) * 1em;
}

@function emToRem($size) {
    @return strip-unit($size) * 1rem;
}