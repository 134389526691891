﻿/// <reference path="../../main">

.news-preview {
    @include responsive-context($vertical-margin-map) {
        padding-top: $responsive-variable;
    }

    &-card {
        position: relative;
        list-style: none;
    }

    &-image {
        width: 100%;
        height: 250px;
        margin-bottom: 1rem;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;

        @include media(medium) {
            height: 200px;
        }
    }

    &-meta {
        display: flex;
        justify-content: space-between;
        margin: 0 0 1.25rem 0;
    }

    &-category,
    &-date {
        font-size: $_tiny-font-size;
        font-weight: $body-bold;
        line-height: $_tiny-line-height;
        text-transform: uppercase;
        margin: 0;
    }

    &-content {
        display: flex;
        flex-direction: column;
    }

    &-head {
        order: 2;
        margin: 0;
    }

    &-link {
        color: $black;
        text-decoration: none;
    }
} 