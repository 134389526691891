﻿/// <reference path="../main">

%btn-base {
    display: inline-block;
    text-decoration: none;
    font-family: $body-font;
    font-weight: $body-bold;
    text-align: center;
    cursor: pointer;
    border: 0;
    padding: $_forms-input-padding;
    padding-top: $_button-padding-v + 0.0625rem;
    padding-bottom: $_button-padding-v - 0.0625rem;
    max-width: 100%;
    transition: $transition;
    position: relative;
    vertical-align: middle;
    border-radius: 999em;
    box-shadow: $ui-box-shadow;

    &[disabled] {
        cursor: not-allowed;
    }

    & > * {
        pointer-events: none;
    }

    &:focus {
        @extend %focus-solid;
    }

    @media screen and (-ms-high-contrast: active) {
        border: 2px solid $black;
    }
}

%btn-with-icon {
    padding-right: $_forms-input-padding-h + ($ui-icon-width * 1.5) + $gap-sm;
    text-align: left;
    position: relative;

    &::before,
    &::after {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: $gap-sm;
        width: $ui-icon-width * 1.5;
        height: $ui-icon-height;
        background-position: center center;
        background-size: contain;
        background-repeat: no-repeat;
        transition: $transition;
    }

    &:hover,
    &:focus {
        &::after {
            transform: translate(20%, -50%);
        }

        &[disabled]::after {
            transform: translateY(-50%);
        }
    }
}

%btn-xl-base {
    text-transform: uppercase;
    letter-spacing: $letter-spacing-wide;
    padding-top: $gap-sm - ($_forms-border-width * 2) + 0.0625rem;
    padding-bottom: $gap-sm - ($_forms-border-width * 2) - 0.0625rem;
    font-weight: $body-medium;
}

%btn-with-icon-white {
    @extend %btn-with-icon;
    &::before {
        @include ico-hex-hole($white);
    }

    &::after {
        @include ico-hex-arrow($white);
    }

    &[disabled] {
        &::before {
            @include ico-hex-hole($gray-med);
        }

        &::after {
            @include ico-hex-arrow($gray-med);
        }
    }
}

%btn-with-icon-blue {
    @extend %btn-with-icon;
    &::before {
        @include ico-hex-hole($accessible-teal);
    }

    &::after {
        @include ico-hex-arrow($accessible-teal);
    }

    &:active {
        &::before {
            @include ico-hex-hole($white);
        }

        &::after {
            @include ico-hex-arrow($white);
        }
    }

    &[disabled] {
        &::before {
            @include ico-hex-hole($light-green);
        }

        &::after {
            @include ico-hex-arrow($light-green);
        }
    }
}

%btn {
    @extend %btn-base;
    @extend %btn-with-icon-white;
    @extend %tiny;
}

%btn-outline {
    @extend %btn-base;
    @extend %btn-with-icon-blue;
    @extend %tiny;
}

%white-btn-outline {
    @extend %btn-base;
    @extend %btn-with-icon-white;
    @extend %tiny;
}

%btn-xl {
    @extend %btn-base;
    @extend %btn-with-icon-white;
    @extend %titling;
    @extend %btn-xl-base;
}

%btn-xl-outline {
    @extend %btn-base;
    @extend %btn-with-icon-blue;
    @extend %titling;
    @extend %btn-xl-base;
}

%un-button-base {
    @extend %body;
    @extend %link;
    background-color: transparent;
    border: 0;
    padding: 0;
    margin: 0;
    cursor: pointer;
    display: inline;
    text-align: left;
    font-family: $body-font;
}

%un-button {
    @extend %un-button-base;

    &:hover,
    &:focus {
        background-color: transparent;
    }

    &[disabled],
    &[aria-disabled="true"] {
        background-color: transparent;
        color: $gray-dark;
        cursor: not-allowed;
    }
}

%x-btn {
    // Uses hypotenuse of icon square size to match rotated icon size
    @extend %un-button-base;
    width: $ui-indicator-size-md;
    height: $ui-indicator-size-md;
    min-width: 0;
    position: relative;
    display: inline-block;
    line-height: 1;

    &::before,
    &::after {
        content: '';
        display: block;
        width: hypotenuse($ui-icon-width, $ui-icon-height) * 0.8;
        height: $ui-icon-thickness;
        background-color: $white;
        position: absolute;
        left: 50%;
        top: 50%;
    }

    &::before {
        transform: translateX(-50%) rotate(-45deg);
    }

    &::after {
        transform: translateX(-50%) rotate(45deg);
    }
}

%btn-icon {
    width: $icon-button-size;
    height: $icon-button-size;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0;
    cursor: pointer;

    svg {
        width: $ui-icon-width;
        height: $ui-icon-height;
    }
}
