﻿/// <reference path="../main">

%invalid,
%valid {
    background-position: right $_forms-input-padding-h center;
    background-repeat: no-repeat;
    background-size: $ui-indicator-size-md $ui-indicator-size-md;
    padding-right: $_forms-input-padding-h + (emToRem($ui-indicator-size-md) * 2);

    @include ie-edge {
        background-position: 95% center;
    }
}

%invalid {
    border-color: $ui-invalid;
    box-shadow: 0 0 0 1px $ui-invalid inset;
}

%error-message {
    color: $ui-invalid;
    margin-top: $sliver;
}

%select-state {
    background-position: right $_forms-input-padding-h center,
    right (emToRem($ui-icon-width) + $_forms-input-padding-h + $space) center;
    background-repeat: no-repeat, no-repeat;
    padding-right: $_forms-input-padding-h + (emToRem($ui-indicator-size-md) * 3);
}

%select-invalid {
    @extend %select-state;
}