﻿// Log of all non-0, non-1 z-indexes to keep them straight across the site

$z-main-nav: 10;
$z-skip-link: 1000;
$z-modal: 1500;
$z-expand-x: 1;
$z-highlight-image: 1;
$z-page-link-content: 2;
$z-bubble: 1;
$z-bubble-overlay: 2;
$z-video-carousel-image: 1;
$z-video-carousel-play: 2;
$z-map-overlay: 1;