﻿/// <reference path="../../../main">

.pagination {
    $self: &;

    &-controls {
        & > * {
            margin-right: $gap;

            &:last-child {
                margin-right: 0;
            }
        }
    }

    &-count {
        max-width: $one-third;
    }

    &-header {
        align-items: center;

        @include media(medium) {
            align-items: flex-start;
        }

        #{$self} {
            @include media-min(medium) {
                display: flex;
                align-items: center;

                &-text {
                    margin-right: $gap;
                }
            }

            @include media(medium) {
                text-align: right;

                &-controls {
                    margin-top: $_typography-paragraph-spacing;
                    margin-top: var(--text-margin);
                }
            }
        }
    }

    &-footer {
        @extend %x-large-top-margin;
        text-align: center;

        #{$self} {
            display: flex;
            flex-direction: column-reverse;

            &-text {
                @extend %large-top-margin;

                &,
                & > * {
                    margin-left: auto;
                    margin-right: auto;
                }
            }
        }
    }
}
