﻿/// <reference path="../../main">

.loctype {
    $self: &;

    &[data-watermark] {
        &::before {
            left: 0;
            transform: none;
            white-space: nowrap;

            @include media(medium-small) {
                content: none;
            }
        }
    }

    &-inner {
        display: flex;
        align-items: center;

        @include media(medium-small) {
            flex-direction: column-reverse;
            padding-left: $gap-md;
            padding-right: $gap-md;
        }
    }

    &-content {
        @extend %card-padding;
        transition: $transition all;

        @include media(medium-small) {
            padding-bottom: 0;
            padding-left: 0;
            padding-right: 0;
        }
    }

    &-item {
        // as a carousel slide
        width: 100%;
        position: relative;

        #{$self} {
            transform-origin: center center;
            transition: $transition all;

            @include media(medium-small) {
                transform-origin: center calc(50vw - #{map-get($standard-padding-map, small)});
            }
        }

        &.is-active:not(.is-origin) #{$self},
        &.is-target #{$self} {
            transform: none;
        }

        &.is-on-right #{$self} {
            transform-origin: left center;
            opacity: 0.5;

            @include media(medium-small) {
                transform-origin: left calc(50vw - #{map-get($standard-padding-map, small)});
                transform: translateX(-#{$gap-lg});

                &-content {
                    opacity: 0;
                }
            }
        }

        &.is-on-left #{$self} {
            transform-origin: right center;
            opacity: 0.5;

            @include media(medium-small) {
                transform-origin: right calc(50vw - #{map-get($standard-padding-map, small)});
                transform: translateX(#{$gap-lg});

                &-content {
                    opacity: 0;
                }
            }
        }
    }

    &-image {
        @extend %full-bleed-image;
        border-radius: 999em;

        &-inner {
            @extend %full-bleed-image-inner;
        }

        &-container {
            @extend %full-bleed-image-container;
            width: $one-third;
            flex: 0 0 auto;
            margin-left: $max-gutter-width;
            height: 0;
            padding-bottom: $one-third;
            align-self: stretch;
            display: flex;
            align-items: center;
            transition: $transition all;

            @include media(medium-small) {
                width: 100%;
                margin-left: 0;
                padding-bottom: 100%;
            }
        }
    }
}
