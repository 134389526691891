﻿/// <reference path="../../main">

.underline {
    padding-bottom: $_typography-line-spacing;
    padding-bottom: var(--line-margin);
    margin-bottom: $_typography-line-spacing;
    margin-bottom: var(--line-margin);
    position: relative;

    &::after {
        content: '';
        border-top: $_forms-border-width solid $accent-green;
        width: 1.5em;
        position: absolute;
        bottom: 0;
        left: 0;
        display: block;
    }
}