﻿/// <reference path="../../main">

$_image-ratio-md: 0.75;
$_image-ratio-sm: 1;

.highlight {
    &-block {
        overflow: hidden;

        @include responsive-context($vertical-margin-map) {
            padding-bottom: $responsive-variable;
        }
    }

    .split {
        &-inner {
            @extend %standard-padding-h;
            max-width: $max-page-width;
            width: 100%;
            margin-left: auto;
            margin-right: auto;
            position: relative;
        }

        &-image {
            &-container {
                z-index: $z-highlight-image;

                @include responsive-context($vertical-margin-map) {
                    @if $responsive-breakpoint == medium or $responsive-breakpoint == small {
                        margin-top: -$responsive-variable / 2;
                        margin-bottom: 0;
                    }
                    @else {
                        margin-top: $responsive-variable / 2;
                        margin-bottom: -$responsive-variable;
                    }
                }

                @include responsive-context($standard-padding-map, $gutter-map) {
                    @if $responsive-breakpoint == medium or $responsive-breakpoint == small {
                        width: 75%;
                    }
                    @else {
                        // MAAAAATH!!!! Need image to be 5 columns + 1 gutter and jut out by 1/2 standard padding amount
                        width: calc(#{5 / 12 * 100%} + #{-(5 * 11 / 12 * $responsive-variable-2) + (5 * $responsive-variable-2) + ($responsive-variable / 2)});
                    }
                }
            }
        }

        &-image-left {
            .split {
                &-image-container {
                    @include responsive-context($standard-padding-map, $gutter-map) {
                        @if $responsive-breakpoint == medium or $responsive-breakpoint == small {
                            margin-left: 25%;
                            margin-right: 0;
                        }
                        @else {
                            margin-left: -$responsive-variable / 2;
                            margin-right: getColumnWidth(12, 1, true, $responsive-variable-2);
                        }
                    }
                }

                &-text-container {
                    padding-right: 0;
                }
            }

            [data-watermark]::before {
                @include media-min(medium) {
                    left: 75%;
                }
            }
        }

        &-image-right {
            .split {
                &-image-container {
                    @include responsive-context($standard-padding-map, $gutter-map) {
                        @if $responsive-breakpoint == medium or $responsive-breakpoint == small {
                            margin-left: 25%;
                            margin-right: 0;
                        }
                        @else {
                            margin-right: -$responsive-variable / 2;
                            margin-left: getColumnWidth(12, 1, true, $responsive-variable-2);
                        }
                    }
                }

                &-text-container {
                    padding-left: 0;
                }
            }

            [data-watermark]::before {
                @include media-min(medium) {
                    left: 25%;
                }
            }
        }

        &-text {
            @include media(medium) {
                padding-left: 0;
                padding-right: 0;
            }

            &-container {
                flex: 1 1 auto;
                position: static;
            }
        }
    }
}

//Mobile image positioning
.highlight .split {
    &-image-container {
        @include media(medium) {
            height: 0;
            min-height: 0;
            padding-bottom: $_image-ratio-md * 100%;
            position: relative;
            right: 0;
            margin-top: -$_image-ratio-md / 2 * 100%;
            transform: translateY(50%);
        }
        @include media(small) {
            padding-bottom: $_image-ratio-sm * 100%;
            margin-top: -$_image-ratio-sm / 2 * 100%;
        }
    }
}

.highlight-block {
    @include media(medium) {
        padding-bottom: calc(#{$_image-ratio-md / 2 * 100%} - #{map-get($standard-padding-map, medium) * $_image-ratio-md});
    }

    @include media(small) {
        padding-bottom: calc(#{$_image-ratio-sm / 2 * 100%} - #{map-get($standard-padding-map, small) * $_image-ratio-sm});
    }
}