﻿/// <reference path="../../main">
.carousel {
    &-list {
        &,
        &-desktop {
            padding-top: $gap-md;
            padding-bottom: $gap-md;
            width: auto;
        }
        &-desktop {
            @extend %standard-padding-h;
            max-width: $max-page-width;
            margin-left: auto;
            margin-right: auto;
        }
    }

    &-controls {
        display: flex;
        justify-content: flex-end;

        &-mobile-only {
            @include media-min(small) {
                display: none;
            }
        }
    }

    &-slide {
        // this will need to be edited for future carousels
        @include media(small) {
            margin-top: 0;
        }
    }

    &-arrow {
        flex: 0 0 auto;
        margin-top: $gap-md;
        margin-bottom: $gap-md;

        & + & {
            margin-left: $gap-sm;
        }
    }
}

// Fun slidey pagination
.carousel {
    &-pagination {
        @extend %standard-padding-h;
        width: auto;
        display: flex;
        align-items: center;
        max-width: $max-page-width;
        margin-left: auto;
        margin-right: auto;

        & > li {
            flex: 1 1 auto;
            display: flex;
            align-items: center;
        }
    }

    &-page {
        @extend %un-button;
        width: 100%;
        position: relative;
        overflow: hidden;
        height: $ui-icon-thickness;

        &::before,
        &::after {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            border-top: $ui-icon-thickness solid $gray-light;
        }

        &:focus {
            box-shadow: 0 0 0 2px $gray-med-light;

            &.is-active {
                box-shadow: none;
            }

            &::before {
                border-color: $gray-med-light;
            }
        }

        &:active {
            background: transparent;
        }

        &::after {
            border-color: $accent-blue;
            transform: translateX(-100%);
            animation: carouselTrackOffToRight $transition;
        }

        &.is-active::after {
            transform: translateX(0);
            animation: carouselTrackOnFromLeft $transition;
        }
    }

    [data-slide-direction="left"] &-page {
        &::after {
            transform: translateX(100%);
            animation: carouselTrackOffToLeft $transition;
        }

        &.is-active::after {
            transform: translateX(0);
            animation: carouselTrackOnFromRight $transition;
        }
    }

    &-page-inactive {
        &::after {
            animation: none !important;
        }
    }
}

// First and last index numbers in track bar as seen on some carousels
.carousel-pagination-nums {
    &[data-start-num][data-end-num] {
        &::before,
        &::after {
            color: $accent-blue;
            width: 1.25rem;
        }

        &::before {
            content: attr(data-start-num);
            margin-right: $gap-sm;
        }

        &::after {
            content: attr(data-end-num);
            margin-left: $gap-sm;
            text-align: right;
        }

        @include media(small) {
            &::before,
            &::after {
                content: none;
            }
        }
    }
}

.carousel-controls-nums {
    & > *:last-child {
        margin-right: $gap-sm + 1.25rem;

        @include media(small) {
            margin-right: 0;
        }
    }
}

@keyframes carouselTrackOnFromLeft {
    0% {
        transform: translateX(-100%);
    }

    100% {
        transform: translateX(0);
    }
}

@keyframes carouselTrackOffToRight {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(100%);
    }
}

@keyframes carouselTrackOnFromRight {
    0% {
        transform: translateX(100%);
    }

    100% {
        transform: translateX(0);
    }
}

@keyframes carouselTrackOffToLeft {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(-100%);
    }
}
