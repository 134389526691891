﻿/// <reference path="../../main">

.link-pair {
    $self: &;
    display: flex;
    width: 100%;
    box-shadow: 0 2px 5px 0 rgba(19,19,25,0.2);
    border-radius: $lg-border-radius;

    &-item {
        @include responsive-context($card-padding-map-h, $card-padding-map-v) {
            padding: $responsive-variable-2 $responsive-variable;
        }

        width: 50%;
        flex: 1 1 50%;
        position: relative;
        overflow: hidden;
        transition: $transition all;
        display: flex;

        &:only-child {
            width: 100%;
            flex-basis: 100%;
        }

        &:first-child {
            border-top-left-radius: $lg-border-radius;
            border-bottom-left-radius: $lg-border-radius;
        }

        &:last-child {
            border-top-right-radius: $lg-border-radius;
            border-bottom-right-radius: $lg-border-radius;
        }

        &::before,
        &::after {
            @extend %full-cover;
            content: '';
            pointer-events: none;
            opacity: 0;
            transition: $transition;
        }

        &::after {
            z-index: $z-highlight-image;
        }

        @each $theme, $values in $colors {
            &.theme-#{$theme} {
                &::before {
                    background-image: map-get($values, 'gradient');
                }

                &::after {
                    background-image: linear-gradient(223.87deg, rgba(map-get($values, 'watermark'), 0) 0%, map-get($values, 'watermark') 100%);
                }
            }
        }

        &:hover {
            &::before,
            &::after {
                opacity: 1;
            }

            #{$self}-image {
                opacity: 0.26;
            }
        }

        &:focus-within {
            // separate from above since IE would ignore the whole rule
            &::before,
            &::after {
                opacity: 1;
            }

            #{$self}-image {
                opacity: 0.26;
            }
        }

        & > * {
            position: relative;
            z-index: $z-page-link-content;
            max-width: 100%;
        }

        & + & {
            @extend %large-top-margin;

            @include media-min(medium-small) {
                margin-top: 0;
            }
        }
    }

    &-image {
        @extend %full-cover;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
        opacity: 0;
        position: absolute;
        transition: $transition;
        z-index: $z-highlight-image;
    }

    &-content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        & > * {
            max-width: 100%;
            min-height: 1px;
        }
    }
}

// Mobile
@include media(medium-small) {
    .link-pair {
        $self: &;
        display: block;
        box-shadow: none;

        &-item {
            border-top-left-radius: $lg-border-radius;
            border-top-right-radius: $lg-border-radius;
            border-bottom-left-radius: $lg-border-radius;
            border-bottom-right-radius: $lg-border-radius;
            width: 100%;

            &::before,
            &::after {
                opacity: 1;
            }

            #{$self}-image {
                opacity: 0.26;
            }
        }
    }
}