﻿/// <reference path="../../main">

.loading-indicator {
    color: $secondary-text;
    font-size: 0.5rem;
    margin: 5em auto;
    margin-left: 4em;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    position: relative;
    text-indent: -9999em;
    animation: spinner 1.3s infinite linear;
    transform: translateZ(0);

    &-container {
        margin-left: auto;
        margin-right: auto;
        width: 4.5em;
    }
}