﻿/// <reference path="../../../main">

.job-info {
    &-list,
    &-container {
        justify-content: space-between;
        align-items: center;
    }

    &-container {
        display: flex;

        @include media(medium) {
            flex-direction: column-reverse;
            align-items: stretch;
        }
    }

    &-list {
        @include media-min(medium) {
            display: flex;
            flex: 1 1 auto;

            &::after {
                // flex spacer
                content: '';
                display: block;
            }
        }

        @include media(medium) {
            &:nth-child(n) {
                margin-top: $gap-lg;
            }
        }
    }

    &-cta {
        text-align: right;
    }

    &-item {
        flex: 0 1 auto;
        margin-right: $space;
        max-width: 100%;

        @include media-min(medium) {
            margin-top: 0;
        }
    }
}
