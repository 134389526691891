﻿@keyframes spinner {
    0%, 100% {
        box-shadow: 0 -3em 0 0.1em, 2em -2em 0 -0.1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -0.1em;
    }

    12.5% {
        box-shadow: 0 -3em 0 -0.1em, 2em -2em 0 0.1em, 3em 0 0 -0.1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
    }

    25% {
        box-shadow: 0 -3em 0 -0.4em, 2em -2em 0 -0.1em, 3em 0 0 0.1em, 2em 2em 0 -0.1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
    }

    37.5% {
        box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -0.1em, 2em 2em 0 0.1em, 0 3em 0 -0.1em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
    }

    50% {
        box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -0.1em, 0 3em 0 0.1em, -2em 2em 0 -0.1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
    }

    62.5% {
        box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -0.1em, -2em 2em 0 0.1em, -3em 0 0 -0.1em, -2em -2em 0 -1em;
    }

    75% {
        box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -0.1em, -3em 0em 0 0.1em, -2em -2em 0 -0.1em;
    }

    87.5% {
        box-shadow: 0em -3em 0 -0.1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -0.1em, -3em 0em 0 -0.1em, -2em -2em 0 0.1em;
    }
}

@keyframes bounce-down {
    0% {
        transform: translate(0);
    }

    50% {
        transform: translateY(25%);
    }

    100% {
        transform: translate(0);
    }
}

@keyframes splide-loading {
    0% {
        transform: rotateZ( 0 );
    }

    100% {
        transform: rotateZ( 360deg );
    }
}