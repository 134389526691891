﻿/// <reference path="../../main">

// Breakpoints
$x-large-media-query-size: 1440px;
$large-media-query-size: 1200px;
$medium-media-query-size: 1024px;
$medium-small-media-query-size: 760px;
$small-media-query-size: 600px;
$smallish-media-query-size: 480px;
$x-small-media-query-size: 384px;

$nav-breakpoint: $medium-media-query-size;

// Spacing
$sliver: 0.25rem;
$space: 0.5rem;
$gap-sm: 1rem;
$gap: 1.5rem;
$gap-md: 2.5rem;
$gap-lg: 3rem;
$gap-xl: 4rem;
$gap-xxl: 6rem;

// Map Maxes/Mins
$max-standard-pad: 9rem;
$max-gutter-width: 2rem;
$min-gutter-width: 2rem;
$min-gutter-height: 1.5rem;
$max-vertical-margin: 9rem;

// UI Sizing
$max-input-width: 24rem;
$button-width: 9.25rem;
$button-width-lg: 12.5rem; 
$ui-icon-width: 1.25rem;
$ui-icon-height: 1.25rem;
$ui-icon-thickness: 3px;
$ui-indicator-size: 0.75rem;
$ui-indicator-size-md: 1rem;
$ui-indicator-size-lg: 2rem;
$ui-indicator-thickness: 1px;
$icon-button-size: 3.5rem;
$svg-vertical-adjust: 0.25em; // magic number determined by the way the svg icons sit in their boundaries
$btn-border-width: 3px;
$common-border-radius: 7px;
$medium-border-radius: 11px;
$lg-border-radius: 21px;
$min-cell-width: 7rem;

// Main nav logo
$logo-width: 6rem;
$logo-height: 2.5rem;
$logo-width-mobile: 4.5rem;
$logo-height-mobile: 1.5rem;

// Navigation
$nav-desktop-height: 8rem;
$nav-desktop-height-collapsed: 6rem;
$nav-mobile-height: 6rem;
$nav-mobile-height-collapsed: 4.5rem;

// Radios/checkboxes
$_radiocheck-background: $white;
$_radiocheck-foreground: $black;
$_radiocheck-accent: $black;
$_radiocheck-size: 1.125em;
$_radiocheck-padding: 0.5em;
$_radiocheck-top-offset: 0;

// Layout Sizing
$max-content-width: 95rem;
$max-page-width: $max-content-width + ($max-standard-pad * 2);

// Miscellaneous
$transition: 0.5s;
$slower-transition: 0.66s;
$one-third: 100%/3.001;
$ui-box-shadow: 1px 3px 14px 0 rgba($gray-dark,0.36);
$box-shadow-3d: 0px 5px 20px 0 rgba($gray-dark,0.36);
$container-box-shadow: 0 0 20px 0 rgba($gray-dark,0.25);
$spinner-size: 1.5rem;

// Forms
$_forms-max-input-width: $max-input-width;
$_forms-input-height: 2.5rem;
$_forms-border-width: pxToRem(2px);
$_forms-input-height-minus-borders: $_forms-input-height - ($_forms-border-width * 2);
$_forms-input-padding-h: 1.5rem;
$_forms-input-line-height: 1.25;
$_forms-input-padding-v: ($_forms-input-height-minus-borders - $_forms-input-line-height) / 2;
$_button-padding-v: ($_forms-input-height-minus-borders - ($_tiny-font-size * $_tiny-line-height)) / 2;
$_forms-input-padding: $_forms-input-padding-v $_forms-input-padding-h;
$_forms-highlight-color: $dark-green;