﻿/// <reference path="../../main">

.home-banner {
    min-height: calc(100vh - #{$nav-desktop-height});
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: relative;

    @include media($nav-breakpoint) {
        min-height: calc(100vh - #{$nav-mobile-height});
    }

    &-image {
        flex: 0 0 auto;
        @extend %full-bleed-image;

        &-picture {
            @extend %full-bleed-image-inner;
        }

        &-container {
            @extend %full-bleed-image-container;
            @extend %full-cover;
            position: absolute;
        }
    }

    &-scroll {
        padding-bottom: $gap;

        &-icon {
            fill: $white;
        }
    }

    &-content {
        flex: 0 1 auto;
        position: relative;
        background: rgba($medium-dark-blue, 0.7);
        
        @include responsive-context($card-padding-map-v) {
            padding-top: $responsive-variable;
        }

        &-inner {
            display: flex;
            align-items: flex-end;

            @include media(medium) {
                display: block;
            }
        }
    }

    &-heading,
    &-description {
        @include responsive-context($gutter-map) {
            width: calc(50% - #{$responsive-variable / 2});
            margin-right: $responsive-variable;
        }

        @include media(medium) {
            width: 100%;
            margin-right: 0;
        }
    }

    &-description {
        margin-right: 0;

        @include media(medium) {
            margin-top: $_typography-paragraph-spacing * 3;
        }
    }
}
