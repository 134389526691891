﻿/// <reference path="../../../main">

$_submenu-width: 11rem;
$_nav-item-padding: $space;

.desktop.nav {
    $self: &;

    @include media($nav-breakpoint) {
        display: none;
    }

    height: $nav-desktop-height;
    box-shadow: $container-box-shadow;
    padding-left: $gap;
    padding-right: $gap;
    display: flex;
    justify-content: center;

    @include ie {
        height: $nav-desktop-height !important;
    }

    @include media-min(x-large) {
        padding-left: $gap-lg;
        padding-right: $gap-lg;
    }

    &-inner {
        max-width: $max-content-width;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex: 1 1 auto;
    }

    &.collapsed {
        height: $nav-desktop-height-collapsed;

        #{$self}-main-0-link::after {
            bottom: -$space;
        }
    }

    &-main {
        $self: &;
        flex: 1 1 auto;
        align-self: stretch;
        display: flex;
        justify-content: flex-end;

        &-0,
        &-1 {
            &-link {
                @extend %small;

                @include media(large) {
                    font-size: $_tiny-font-size;
                    line-height: $_tiny-line-height;
                }
            }
        }

        &-0 {
            &-ul {
                display: flex;
                justify-content: space-between;
            }

            &-li {
                flex: 0 1 auto;
                position: relative;
                display: flex;
                align-items: center;
                padding: 0 $_nav-item-padding;

                &:not(:hover):not(.open) #{$self}-1-submenu {
                    height: 0 !important;
                }

                &:hover,
                &.open {
                    &::after {
                        opacity: 1;
                    }
                }

                &::before {
                    content: '';
                    position: absolute;
                    pointer-events: none;
                    background: $white;
                    height: 100%;
                    width: calc(#{$_submenu-width} + #{$_nav-item-padding * 2});
                    bottom: 0;
                    left: 0;
                    right: 0;
                    z-index: $z-main-nav - 1;
                }

                &::after {
                    content: '';
                    position: absolute;
                    border-top: 2px solid $accessible-teal;
                    left: $_nav-item-padding;
                    right: $_nav-item-padding;
                    bottom: 0;
                    z-index: $z-main-nav - 1;
                    opacity: 0;
                    transition: $transition;
                }
            }

            &-link {
                padding: $_forms-input-padding-v 0;
                position: relative;
                z-index: $z-main-nav;
                line-height: 1;
                border-bottom-style: dotted;

                &:not(:focus) {
                    border-bottom-color: transparent;
                }

                &:nth-child(n) {
                    border-top-color: transparent;
                }
            }
        }

        &-1 {
            &-submenu {
                box-shadow: $box-shadow-3d;
                position: absolute;
                top: 100%;
                left: -$_nav-item-padding * 2;
                background: $white;
                width: calc(#{$_submenu-width} + #{$_nav-item-padding * 2});
                z-index: $z-main-nav - 2;
                overflow: hidden;
                transition: $transition height;
                text-align: left;
            }

            &-ul {
                max-height: calc(100vh - #{$nav-desktop-height});
                overflow: auto;
                padding: $gap ($_nav-item-padding * 3);
            }

            &-li + &-li {
                @extend %responsive-top-margin;
            }
        }
    }

    &-search {
        padding-left: $_forms-input-padding-h;
        position: relative;
        z-index: $z-main-nav;
        flex: 0 0 auto;
    }
}
