﻿/// <reference path="../../main">

.social-list {
    display: flex;
    align-items: center;

    &-item {
        margin: 0;
        margin-right: $gap-sm;
        display: flex;
        align-items: center;

        &:last-child {
            margin-right: 0;
        }
    }
}