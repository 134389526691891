﻿/// <reference path="../../main">

.carousel-hex {
    &-pagination {
        padding-left: 0;
    }

    &-page {
        @extend %un-button;
        height: $ui-indicator-size-lg;
        width: $ui-indicator-size-lg;
        position: relative;

        @include ico-hex-dot($medium-dark-blue, 'outline');

        @include media(small) {
            height: $ui-indicator-size-lg * 0.75;
            width: $ui-indicator-size-lg * 0.75;
        }

        &:focus {
            box-shadow: none;
        }

        &,
        &::before,
        &::after {
            background-repeat: no-repeat;
            background-position: center center;
            background-size: contain;
            transition: $transition;
        }

        &::before,
        &::after {
            @extend %full-cover;
            content: '';
            opacity: 0;
        }

        &:hover,
        &:focus {
            &::before {
                opacity: 0.8;
            }
        }

        &::before {
            @include ico-hex-dot($white, 'solid');
        }

        &::after {
            @include ico-hex-dot($white, 'frame');
            transform: rotate(150deg) scale(0.8);
        }

        &.is-active,
        &[aria-expanded="true"] {
            &::before {
                opacity: 1;
            }

            &::after {
                opacity: 1;
                transform: none;
            }
        }
    }
}
