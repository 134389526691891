@mixin media($breakpoint, $breakpoints-map: $breakpoints) {
    @if map-has-key($breakpoints-map, $breakpoint) {
        $breakpoint: map-get($breakpoints-map, $breakpoint);
    }

    @media (max-width: $breakpoint) {
        @content;
    }
}

@mixin media-min($breakpoint, $breakpoints-map: $breakpoints) {
    @if map-has-key($breakpoints-map, $breakpoint) {
        $breakpoint: map-get($breakpoints-map, $breakpoint);
    }

    @media (min-width: $breakpoint + 1px) {
        @content;
    }
}

@mixin media-range($breakpoint-min, $breakpoint-max, $breakpoints-map: $breakpoints) {
    @if map-has-key($breakpoints-map, $breakpoint-min) {
        $breakpoint-min: map-get($breakpoints-map, $breakpoint-min);
    }

    @if map-has-key($breakpoints-map, $breakpoint-max) {
        $breakpoint-max: map-get($breakpoints-map, $breakpoint-max);
    }

    @media (min-width: $breakpoint-min + 1px) and (max-width: $breakpoint-max) {
        @content;
    }
}
