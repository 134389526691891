﻿/// <reference path="../../main">

.validation-summary-errors {
    margin-left: 0;
    color: $ui-invalid;
    border: $_forms-border-width dotted $ui-invalid;
    padding: $_forms-input-padding;
    border-radius: $common-border-radius;
    max-width: $max-line-length;
    font-weight: $body-bold;

    a {
        @include link-helper($ui-invalid, $primary-text, $ui-invalid);
    }

    @at-root {
        .visually-hidden:first-child + * {
            margin-top: 0;
        }
    }

    &:first-child {
        margin-top: 0;
    }
}

.validation-summary-valid {
    display: none;
}