﻿/// <reference path="../../main">

.testimonial {
    $self: &;
    display: flex;
    flex-wrap: wrap;

    &-image {
        @extend %full-bleed-image-container;
        width: getColumnWidth(12, 5, false, $max-gutter-width, false);
        flex: 0 0 auto;
        border-radius: $common-border-radius;
        overflow: hidden;
        position: relative;

        &-inner {
            @extend %full-bleed-image-inner;
        }

        &-img {
            @extend %full-bleed-image;
        }
    }

    &-bio {
        text-align: center;
        margin-top: $gap;
        order: 3;
        width: getColumnWidth(12, 5, false, $max-gutter-width, false);
    }

    &-quote {
        @extend %standard-padding-h;
        padding-top: $gap-xl;
        padding-bottom: $gap-xl;
        margin-top: $gap-lg;
        margin-bottom: $gap-lg;
        width: getColumnWidth(12, 7, false, $max-gutter-width, true);
        flex: 1 1 auto;
        display: flex;
        align-items: center;
        border-top-right-radius: $common-border-radius;
        border-bottom-right-radius: $common-border-radius;
    }

    &-item {
        // as a carousel slide
        width: 100%;

        #{$self} {
            transform-origin: center 40%;
            transition: $transition all;
            transform: scale(0.8);
        }

        &.is-active:not(.is-origin) #{$self},
        &.is-target #{$self} {
            transform: none;
        }

        &.is-on-right #{$self} {
            transform-origin: left 40%;
        }

        &.is-on-left #{$self} {
            transform-origin: right 40%;
        }
    }

    &-heading {
        border-bottom-style: solid;
        border-bottom-width: 1px;
        padding-bottom: $gap;
        margin-bottom: $gap-lg;
        display: none;

        &-bio {
            flex: 1 1 auto;
            margin-left: $gap;
        }
    }

    &-thumb {
        @extend %full-bleed-image-container;
        width: $icon-button-size;
        height: $icon-button-size;
        border-radius: 999em;
        overflow: hidden;
        flex: 0 0 auto;

        &-inner {
            @extend %full-bleed-image-inner;
        }

        &-img {
            @extend %full-bleed-image;
        }
    }
}

// Mobile version
@include media(medium) {
    .testimonial {
        &-image {
            display: none;
        }

        &-bio {
            display: none;
        }

        &-quote {
            width: 100%;
            display: block;
            border-radius: $common-border-radius;
            padding: $gap-md;
            margin-top: 0;
            margin-bottom: 0;
        }

        &-heading {
            display: flex;
        }
    }
}
