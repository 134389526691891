﻿/// <reference path="../../main">

table {
    border-collapse: collapse;

    caption {
        @extend %h5;
        padding-bottom: $space;
        text-align: left;
    }
}

table,
td,
th {
    border: 1px solid $gray-med;
}

td,
th {
    padding: $space;
    min-width: $min-cell-width;
}

th {
    @extend %bg-light;
}