﻿/// <reference path="../../main">

.job-listing {
    &-block {
        padding-bottom: $gap-sm;
    }

    &-main {
        @include media-min(medium) {
            @include column-width(12, 5, false, false);
        }

        @include media-range(medium-small, medium) {
            @include column-width(12, 6, false, false);
        }

        @include media-range(small, medium-small) {
            @include column-width(12, 8, false, false);
        }

        @include media(small) {
            @include column-width(12, 11, false, false);
        }
    }
}
