﻿/// <reference path="../main">

%link {
    @include link-helper();
    text-decoration: underline;
}

%primary-link {
    @extend %link;
    @extend %titling;
    font-weight: $body-regular;
    text-decoration: none;

    &:hover,
    &:focus {
        text-decoration: underline;
    }
}

%white-link {
    @include link-helper($white, $white, $gray-lightest);
}
