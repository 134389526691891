﻿/// <reference path="../../main">

.footer-block,
footer .block-banner {
    padding-left: $gap-lg;
    padding-right: $gap-lg;
    padding-top: 0;
    padding-bottom: 0;
    overflow: hidden;
    position: relative;
}

.footer-inner,
footer .block-inner {
    max-width: $max-content-width;
    margin-left: auto;
    margin-right: auto;
    padding-left: 0;
    padding-right: 0;
    position: relative;

    @include responsive-context($medium-margin-map) {
        padding-top: $responsive-variable;
        padding-bottom: $responsive-variable;
    }
}

.footer {
    &-top {
        @extend %two-col-container;
        padding-top: $gap-md * 2;

        & > * {
            @extend %two-col-column;

            @include media(medium-small) {
                margin-bottom: 0;
            }
        }
    }

    &-main {
        display: flex;
        flex-direction: column;
        align-self: stretch;

        & > * {
            max-width: 100%;
        }

        &-home {
            flex: 0 0 auto;
        }

        &-text {
            flex: 0 1 auto;
            margin-top: auto;
            margin-bottom: auto;
            padding-top: $gap-md * 2;

            & > .h1-style {
                max-width: 12ch;
            }
        }
    }

    &-navs {
        display: flex;
        margin-top: $gap-md * 2;

        @include media(medium-small) {
            justify-content: center;
        }
    }

    &-links {
        @include media(medium-small) {
            display: flex;
            flex-direction: column;

            & > * {
                max-width: 100%;
            }
        }

        &-top {
            text-align: right;

            @include media(medium-small) {
                display: none;
            }
        }

        &-bottom {
            @include responsive-context($medium-margin-map) {
                margin-top: $responsive-variable;
            }

            align-self: flex-end;
            flex: 0 0 auto;

            @include media-min(medium-small) {
                display: none;
            }
        }

        &-main {
            text-align: right;
            display: flex;

            &-list {
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                & > * {
                    max-width: 100%;
                }
            }
        }

        &-secondary {
            @include responsive-context($gutter-map) {
                margin-left: $responsive-variable;
                padding-left: $responsive-variable;
            }

            border-left-style: solid;
            border-left-width: 1px;
        }
    }

    &-watermark {
        position: absolute;
        fill: $accessible-teal;
        width: 70vw;
        height: 30vw;
        left: -10vw;
        top: -$gap;
        opacity: 0.5;

        @include media(medium-small) {
            width: 120%;
            height: 50vw;
        }
    }

    &-block.theme-dark {
        background: $dark-green;
    }

    &-end {
        display: flex;
        align-items: center;
        padding-top: $gap-sm;
        padding-bottom: $gap-sm;

        // nested anchor rules because these are WYSIWYGs
        a {
            @extend %small;
            font-size: $_tiny-font-size;
            text-decoration: none;

            &:not([class*="svg"]):hover {
                text-decoration: underline;
            }
        }

        &-group {
            flex: 1 1 auto;
            justify-content: space-between;

            @include media(medium-small) {
                flex-flow: wrap-reverse;

                & > * {
                    width: 100%;
                }
            }

            &,
            &-inner {
                display: flex;
                align-items: center;

                @include media(x-small) {
                    display: block;
                }

                & > * {
                    margin-top: 0;
                    margin-right: $gap-lg;

                    @include media(small) {
                        margin-right: $gap-sm;
                    }
                }
            }

            &-inner {
                margin-right: 0;
            }
        }
    }
}

// Job search edits
footer {
    .job-search-block {
        background: $navy-blue;
    }

    .block {
        padding-top: 0;
    }
}