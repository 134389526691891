﻿/// <reference path="../../main">

.btn,
.btn-primary {
    @extend %btn;
    @include button-helper();
}

.btn-xl {
    @extend %btn-xl;
    @include button-helper();
}

.btn-outline {
    @extend %btn-outline;
    @include button-helper(transparent, $dark-green, $accessible-teal, $white, transparent, $light-green, true);
}

.btn-xl-outline {
    @extend %btn-xl-outline;
    @include button-helper(transparent, $dark-green, $accessible-teal, $white, transparent, $light-green, true);
}

.un-button {
    @extend %un-button;
}

.btn-text {
    @extend %btn;
    @include button-helper(transparent, transparent, $accent-green, $medium-blue, transparent, $gray-dark, false);
    padding-left: $sliver;
    margin-left: -$sliver;
    padding-right: $_forms-input-padding-h + ($ui-icon-width * 2);
    box-shadow: none;
    border-radius: 0;

    &::before,
    &::after {
        right: $space;
    }

    &::before {
        @include ico-hex-hole($accent-green);
    }

    &::after {
        @include ico-hex-arrow($accent-green);
    }

    &:focus {
        box-shadow: inset $accent-green 0 0 0 1px, inset $white 0 0 0 3px;
    }
}