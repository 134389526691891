﻿/// <reference path="../../main">

.awards-carousel {
    &-wrapper {
        display: flex;
        padding-bottom: $gap-xxl;

        @include responsive-context($standard-padding-map) {
            padding-left: $responsive-variable;
            padding-right: $responsive-variable;
        }

        @include media-min(medium-small) {
            padding-right: 0;
            padding-bottom: 0;
        }

        .carousel-pagination {
            padding: 0 $gap-sm;
            width: 100%;
        }
    }

    &-container {
        flex: 1 0 auto;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        @include media-range(medium-small,medium) {
            @include column-width(12, 6, false, true);
        }

        @include media-min(medium) {
            @include column-width(12, 8, false, true);
        }

        .carousel-track {
            height: 100%;
        }
    }

    &-list {
        padding-top: 0;
        padding-bottom: $gap-xl;
        height: 100%;
    }

    @include media(medium-small) {
        &-wrapper {
            flex-direction: column;
        }

        &-container {
            max-width: none;
            margin-top: $gap;
        }
    }

    &-sidebar {
        flex: 1 0 auto;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        padding-right: 0;
        width: 100%;

        @include media-range(medium-small,medium) {
            @include column-width(12, 6, false, false);
            padding-right: $gap-xl;
        }

        @include media-min(medium) {
            @include column-width(12, 4, false, false);
            padding-right: $gap-xl;
        }

        .carousel-arrow {
            margin-bottom: 0;
        }
    }

    &-arrows {
        @include media(medium-small) {
            position: absolute;
            bottom: $gap;
            right: $gap-sm * 2;
        }
    }
}

// Awards Card Styles
.awards-card {

    &-item {
        background: $white;
        padding: $gap-sm;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        box-shadow: $ui-box-shadow;

        &, &:first-child {
            @include media(medium-small) {
                margin-top: $gap-sm;
            }
        }

        &:not(.is-active) {
            opacity: 0.5;
        }
    }

    &-media {
        max-width: 33%;
    }

    &-description {
        text-align: right;
    }
}
