﻿/// <reference path="../../main">

.fiftyfifty .split {
    &-item {
        @include media-min(medium) {
            flex: 0 0 auto;
            width: 50%;
        }
    }

    &-text {
        @include responsive-context($gutter-map) {
            max-width: ($max-content-width - $responsive-variable) / 2;
        }

        @include media-min(medium) {
            @supports(display: grid) {
                grid-template-columns: repeat(6, minmax(0, 1fr));
            }
        }

        @include media-min(medium) {
            @supports(display: grid) {
                padding-left: 0;
                padding-right: 0;
                display: grid;
                grid-column-gap: var(--gutter);
            }
        }
    }

    &-image {
        border-radius: 0;
    }

    &-image-right &-text-inner {
        @supports(display: grid) {
            grid-column: 1 / span 5;
        }
    }

    &-image-left &-text-inner {
        @supports(display: grid) {
            grid-column: 2 / span 5;
        }
    }
}

// left and right alignment
.fiftyfifty .split {
    @include media-min(medium) {
        &-image-left .split-text {
            margin-left: 0;
            margin-right: auto;

            &-container {
                @supports(display: grid) {
                    padding-left: calc(var(--gutter) / 2);
                }
            }
        }

        &-image-right .split-text {
            margin-right: 0;
            margin-left: auto;

            &-container {
                display: flex;
                justify-content: flex-end;

                @supports(display: grid) {
                    padding-right: calc(var(--gutter) / 2);
                }
            }
        }
    }
}