﻿/// <reference path="../../main">

.fwcta {
    &-inner {
        @include media-min(medium-small) {
            display: flex;
            justify-content: space-between;
            align-items: center;

            & > * {
                flex: 0 1 auto;
            }

            .rich-text-cta {
                margin-top: 0;
            }
        }

        @include responsive-context($gutter-map, $all-breakpoints: true) {
            .rich-text {
                @if $responsive-breakpoint == medium {
                    @include column-width(12, 8, false, true);
                }
                @elseif $responsive-breakpoint != small {
                    @include column-width(12, 6, false, true);
                }
            }
        }

        @include media(medium-small) {
            .rich-text {
                width: auto;
            }
        }
    }
}
