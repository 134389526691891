﻿/// <reference path="../../main">

// The majority of styles for this block are handled on the carousel and card component styles

.card-carousel {
    &-text {
        @extend %two-column-text-content;
    }

    &-mobile-cta {
        margin-left: auto;
        margin-right: auto;
        padding-bottom: $gap-sm;

        @include media-min(small) {
            display: none;
        }
    }
}
